.page
    padding-bottom: 80px
    &__head
        padding-top: 40px
        +padding-left(0)
        +padding-right(0)
        padding-bottom: 140px
        background: linear-gradient(101.65deg, #2F80ED 0.16%, #56CCF2 100%)
        font-weight: bold
        font-size: 22px
        color: #FFF
        text-align: center
        position: relative
        z-index: 1
        &-wrapper
            max-width: 1200px
            padding: 0 30px
            margin: 0 auto
        &-title
            +di-flex()
            +jc(space-between)
            +align(center)
            &-main
                font-size: 30px
                letter-spacing: normal
                font-weight: 600
            &-add.mat-mdc-raised-button
                color: $orange
    &__center
        position: relative
        z-index: 2
    &__subtitle
        font-size: 14px
        line-height: 1
        text-align: center
        color: #4a4a4a
        margin-top: 10px
        font-weight: normal
        text-transform: none
        a
            color: #4a4a4a
    &__wrap
        background: #FFF
        box-shadow: 6px 12px 92px 0 rgba(0, 0, 0, 0.08)
        +dd
            margin-top: -100px
        &_noshadow
            box-shadow: none
            background: none
    &_profile &__wrap
        padding-bottom: 15px
        &-center
            +di-flex
            flex-direction: column
    &_profile &__ava
        order: 1
    &_profile &__accounts
        order: 3
    &_profile &__form
        order: 2
    &_profile &__approve
        order: 4
    &__head_dashboard &__center,
    &__head_dashboard-worker &__center
        font-size: 26px
        letter-spacing: 0.5px
        color: #fff
    &__head_dashboard &__subtitle,
    &__head_dashboard-worker &__subtitle
        text-align: start
        color: #fff
        font-size: 16px
    &_profile &__wrap
        margin-top: 28px
        box-shadow: none
        background: none
    &__footer
        +di-flex
        +jc(space-between)
        +align(center)
        background-color: #fff
        box-shadow: 6px 12px 92px 0 rgba(0, 0, 0, 0.08)
    &__paginator
        +margin-left(auto)
        button.mat-icon-button .mat-mdc-button-wrapper
            +di-flex
            +align(center)
            +jc(center)
    &__breadcrumbs
        width: 50%
        padding-top: 0
        +padding-right(8px)
        padding-bottom: 0
        +padding-left(13px)
    &__fixed
        position: fixed
        z-index: 20
        left: 0
        right: 0
        bottom: 120px
        padding: 0 12px
        display: none
        &-button
            width: 100%
            display: block
            padding: 16px 20px
    +m
        padding-bottom: 0
        &__head
            display: block
            padding: 32px 0
            height: auto
            &-title
                text-align: center
                +jc(center)
                padding-top: 0
                &-main
                    font-size: 22px
                    letter-spacing: normal
                    font-weight: 600
                &-add.mat-mdc-raised-button,
                &-subtitle
                    display: none
            &_create_second
                padding: 15px
                display: none
            &_dashboard,
            &_dashboard-worker
                background: none
                padding: 0
            &_improve
                padding: 20px
                padding-bottom: 120px
                text-align: center
                ~ .page__center
                    margin-top: -100px
            &_guest
                padding: 65px 20px
                text-align: center
            &_favorites
                padding: 65px 20px
                text-align: center
                margin-bottom: 20px
            &_docs
                background: #4a90e2
                display: block
                font-weight: 500
            &_payment
                padding: 65px 20px
                text-align: center
                display: block
                ~ .page__center
                    margin-top: 0px
            &_vacancyNanny
                padding: 32px 0
                height: auto
        &__head_dashboard &__head-wrapper,
        &__head_dashboard-worker &__head-wrapper
            background: none
            padding: 0
        &__head_dashboard &__head-wrapper &__center,
        &__head_dashboard-worker &__head-wrapper &__center
            background: linear-gradient(99deg, #ff4a25, #fa7564)
            padding: 20px 45px
            padding-top: 0
            text-align: center
            font-size: 20px
            letter-spacing: 0.4px
            font-weight: 800
            overflow: hidden
            +rtl
                font-weight: 700
            &:before
                content: ''
                display: block
                width: 247px
                height: 179px
                background-size: contain
                margin: 0 auto
                margin-bottom: 20px
                position: relative
                top: -15px
        &__head_dashboard-worker &__head-wrapper &__center
            background: linear-gradient(98deg, #00cdc9, #00f8f3)
            &:before
                top: 0
                margin-top: 10px
        &_profile &__wrap
            &-center
                padding: 0 20px
        &__head_dashboard &__subtitle,
        &__head_dashboard-worker &__subtitle
            margin: 35px 0
            padding: 0 40px
            font-size: 18px
            text-align: center
            color: #000
            line-height: 1.38
        &_form
            padding-bottom: 0
        &__footer
            margin-bottom: 40px
            flex-wrap: wrap
        &__paginator,
        &__breadcrumbs
            width: 100%
            margin: 10px 0
            text-align: center
        &__paginator
            margin: 0
            .mat-paginator-container
                +jc(center)
        &__fixed
            display: block
        &__center
            z-index: 3
