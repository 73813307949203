// это должен быть в стялях слайдера, но у нас нет стилей слайдера
.slider
    --mdc-slider-handle-width: 20px
    --mdc-slider-handle-height: 20px
    --mdc-slider-handle-color: #1273d6
    --mdc-slider-active-track-height: 2px
    --mdc-slider-inactive-track-height: 2px
    .mat-mdc-slider.mat-primary
        --mdc-slider-inactive-track-color: #e8f0fb
        .mdc-slider__track--inactive
            opacity: 1
    &__el.mdc-slider
        height: 16px
    .nanny &
        --mdc-slider-handle-color: $orange
    .tutor &
        --mdc-slider-handle-color: $blue
    .petcare &
        --mdc-slider-handle-color: $green
    .nurse &
        --mdc-slider-handle-color: $purple
    .driver &
        --mdc-slider-handle-color: $cryola
    .cook &
        --mdc-slider-handle-color: $bondi
    .mat-mdc-slider-horizontal
        height: 22px
    .mdc-slider__input
        z-index: 1
    .mdc-slider__track
        z-index: 2
    .mdc-slider__thumb
        z-index: 3
    .mat-mdc-slider
        display: block
        padding: 0
        width: 100%
        margin: 0
    // .mat-mdc-slider-horizontal .mat-mdc-slider-wrapper
    //     height: 4px
    //     +left(0)
    //     +right(0)
    //     top: 9px
    //     border-radius: 4px
    //     touch-action: none
    // .mat-mdc-slider-horizontal .mat-mdc-slider-track-background
    //     height: 4px
    //     background: $slider_color
    // .mat-mdc-slider-horizontal .mat-mdc-slider-track-fill
    //     height: 4px
    //     background: $slider_color
    // .mat-mdc-slider-horizontal .mat-mdc-slider-track-wrapper
    //     height: 4px
    //     border-radius: 4px
    // .cdk-focused .mat-mdc-slider-track-background, .mat-mdc-slider:hover .mat-mdc-slider-track-background
    //     background: $slider_color
    // .mat-mdc-slider-horizontal .mat-mdc-slider-ticks-container
    //     height: 4px
    // .mat-mdc-slider-horizontal .mat-mdc-slider-focus-ring
    //     display: none
    .mat-mdc-slider .mdc-slider__thumb
        top: -16px
    .mat-mdc-slider .mdc-slider__thumb-knob
        // width: 22px
        // height: 22px
        // +right(-22px)
        // bottom: -11px
        // transform: scale(1)
        background-color: #1273d6
        border-color: #fff
        border-width: 2px
    // .mat-mdc-slider-min-value.mat-mdc-slider-thumb-label-showing .mat-mdc-slider-thumb
    //     background-color: #1273d6
    // .cdk-mouse-focused .mat-mdc-slider-thumb,
    // .cdk-program-focused .mat-mdc-slider-thumb,
    // .cdk-touch-focused .mat-mdc-slider-thumb
    //     border-width: 6px
    // .mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing) .mat-mdc-slider-thumb
    //     background-color: $slider_color
    // .mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing).cdk-focused .mat-mdc-slider-thumb,
    // .mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing):hover .mat-mdc-slider-thumb
    //     border-color: #fff
    //     border-width: 6px
    // .mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing) .mat-mdc-slider-thumb
    //     border-color: #fff
    // .mat-accent .mat-mdc-slider-thumb, .mat-accent .mat-mdc-slider-thumb-label
    //     border-color: #fff
    //     background-color: $slider_color
    //     box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.11)
    // .mat-accent .mat-mdc-slider-track-fill
    //     background-color: $slider_color
    // .mat-mdc-slider-horizontal
    //     height: 16px
    //     .mat-mdc-slider-track-background
    //         background-color: #e8f0fb
    // .mat-mdc-slider-thumb
    //     width: 20px
    //     height: 20px
    //     +right(-20px)
    //     bottom: -9px
    // .mat-mdc-slider-thumb
    //     border-width: 2px
    // .mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing).cdk-focused .mat-mdc-slider-thumb,
    // .mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing):hover .mat-mdc-slider-thumb
    //     border-width: 2px
    // .mat-mdc-slider-horizontal .mat-mdc-slider-track-background,
    // .mat-mdc-slider-horizontal .mat-mdc-slider-track-fill,
    // .mat-mdc-slider-horizontal .mat-mdc-slider-track-wrapper,
    // .mat-mdc-slider-horizontal .mat-mdc-slider-ticks-container
    //     height: 2px
    // .mat-mdc-slider-horizontal .mat-mdc-slider-wrapper
    //     top: 7px
    // .mat-tooltip
    //     font-size: 16px
    +m
        .mat-mdc-slider-horizontal .mat-mdc-slider-track-background,
        .mat-mdc-slider-horizontal .mat-mdc-slider-track-fill,
        .mat-mdc-slider-horizontal .mat-mdc-slider-track-wrapper,
        .mat-mdc-slider-horizontal .mat-mdc-slider-ticks-container
            height: 2px
        .mat-mdc-slider-horizontal .mat-mdc-slider-wrapper
            top: 4px
            border-radius: 2px
        .mat-mdc-slider-thumb
            width: 16px
            height: 16px
            +right(-16px)
            bottom: -7px
            transform: scale(1)
            background-color: #fff
            border-width: 2px

    .nanny .slider__el_color.mat-accent
        .mat-mdc-slider-track-fill,
        .mat-mdc-slider-thumb,
        .mat-mdc-slider-thumb-label
            background-color: $orange
    .tutor .slider__el_color.mat-accent
        .mat-mdc-slider-track-fill,
        .mat-mdc-slider-thumb,
        .mat-mdc-slider-thumb-label
            background-color: $blue
    .petcare .slider__el_color.mat-accent
        .mat-mdc-slider-track-fill,
        .mat-mdc-slider-thumb,
        .mat-mdc-slider-thumb-label
            background-color: $green
    .nurse .slider__el_color.mat-accent
        .mat-mdc-slider-track-fill,
        .mat-mdc-slider-thumb,
        .mat-mdc-slider-thumb-label
            background-color: $purple
    .driver .slider__el_color.mat-accent
        .mat-mdc-slider-track-fill,
        .mat-mdc-slider-thumb,
        .mat-mdc-slider-thumb-label
            background-color: $cryola
    .cook .slider__el_color.mat-accent
        .mat-mdc-slider-track-fill,
        .mat-mdc-slider-thumb,
        .mat-mdc-slider-thumb-label
            background-color: $bondi

    .slider_play
        .mat-mdc-slider-thumb
            width: 15px
            height: 15px
            +right(-7px)
            bottom: -7px
            border-width: 2px
            border-color: #fff
            box-shadow: none
        &.mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing) .mat-mdc-slider-thumb,
        &.mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing):hover .mat-mdc-slider-thumb
            background-color: $orange
            border-color: #fff
            border-width: 2px
            box-shadow: none
        &.mat-mdc-slider-horizontal .mat-mdc-slider-ticks-container,
        &.mat-mdc-slider-horizontal .mat-mdc-slider-track-background,
        &.mat-mdc-slider-horizontal .mat-mdc-slider-track-fill
            height: 2px
        &:not(.mat-mdc-slider-disabled).cdk-mouse-focused .mat-mdc-slider-thumb
            background-color: $orange
            border-color: #fff
            border-width: 2px
            box-shadow: none
        &.mat-mdc-slider-horizontal .mat-mdc-slider-track-background
            background: #ffddd3
    .card-resume__block
        .slider_play
            &.mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing) .mat-mdc-slider-thumb,
            &.mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing):hover .mat-mdc-slider-thumb,
            &:not(.mat-mdc-slider-disabled).cdk-mouse-focused .mat-mdc-slider-thumb,
            &.mat-accent .mat-mdc-slider-track-fill,
            &.mat-accent .mat-mdc-slider-thumb,
            &.mat-accent .mat-mdc-slider-thumb-label
                background-color: #6A93AD
            &.mat-mdc-slider-horizontal .mat-mdc-slider-track-background
                background: #e8f0f3

    .mat-accent .mat-mdc-slider-thumb,
    .mat-accent .mat-mdc-slider-thumb-label
        .tutor &,
        .housekeeper &,
        .nurse &,
        .petcare &
            background-color: $slider_color

    .mat-accent .mat-mdc-slider-track-fill
        .tutor &,
        .housekeeper &,
        .nurse &,
        .petcare &
            background-color: $slider_color

    .slider_play.mat-accent .mat-mdc-slider-thumb, .mat-accent .mat-mdc-slider-thumb-label
        .tutor &,
        .housekeeper &,
        .nurse &,
        .petcare &
            background-color: $orange

    .slider_play.mat-accent .mat-mdc-slider-track-fill
        .tutor &,
        .housekeeper &,
        .nurse &,
        .petcare &
            background-color: $orange
