@import '_colors'
@import '_mixins'

$font: 'SF Pro Display', 'Tahoma', 'Geneva', sans-serif

$color_accent: $orange
$color_light: #CCDCF3

//header
$header_height: 64px
$header_height_mobile: 60px

$chat_left: 360px

$slider_color: #1273d6

\:root
    --color_orange: #FF5722
    --color_nanny: #{$orange}
    --color_tutor: #{$blue}
    --color_housekeeper: #{$bluet}
    --color_petcare: #{$green}
    --color_nurse: #{$purple}
    --color_driver: #{$cryola}
    --color_cook: #{$bondi}
    --color_dove: #{$dove}
    --mdc-theme-error: #d0021b

    --shadow_main: 0 4px 64px rgba(0, 0, 0, 0.06)
    --shadow_light: 2px 4px 124px rgba(105, 105, 105, 0.04)
    --shadow_card: 12px 12px 90px rgba(105, 105, 105, 0.04)

    --header: 64px
    --header_desktop: 64px

    --mdc-typography-button-font-family: #{$font}
    --mdc-typography-body1-font-family: #{$font}
    --mdc-protected-button-label-text-color: var(--orange)
    --mdc-icon-button-icon-size: 100%
    --mdc-theme-primary: #{$orange}
    --mdc-typography-button-letter-spacing: 0
    --mdc-typography-button-line-height: 1
