.mat-floating-placeholder
    opacity: 0!important

.field
    position: relative
    color: #000
    &__label
        margin-bottom: 10px
        color: #000
        font-weight: 500
        font-size: 18px
    &_error &__label,
    &.error &__label
        color: #D0021B
    &__wrap
        position: relative
    &__area
        width: 100%
        border: 1px solid $border
        background: #fff
        font-size: 16px
        display: block
        min-height: 100px
        padding: 10px 20px
        resize: none
        overflow: hidden
        &_comment
            min-height: $header_height
        &.error
            border-color: $red
    &__default
        mat-input-container
            display: block
            width: 100%
            margin: 0
            padding: 0
        textarea
            display: block
            width: 100%
            min-height: 100px
            padding: 10px 20px
            border: 1px solid $border
            background: #fff
            font-size: 16px
            line-height: 1.3
        .mat-resize-handle
            display: none
        label:not(.mat-no-float):not(.mat-container-ignore),
        .mat-placeholder
            top: 11px
            +left(20px)
            +right(20px)
            width: auto
            bottom: 0
            padding: 0
            transform: none
            transition: none
            white-space: normal
            line-height: 1.3
            overflow: hidden
        &_recomm
            position: relative
            // mat-input-container
            textarea
                min-height: 170px
            .mat-errors-spacer
                display: none
        &_chat
            // mat-input-container
            &:after
                display: none
            textarea
                // min-height: 42px
                border: none !important
                resize: none
            label:not(.mat-no-float):not(.mat-container-ignore),
            .mat-placeholder
                white-space: nowrap !important
            .mat-errors-spacer
                display: none
        mat-input-container:not(.mat-input-invalid).mat-input-focused,
        mat-input-container.mat-default-theme:not(.mat-input-invalid).mat-input-resized,
        mat-input-container:not(.mat-input-invalid).mat-input-resized
            .mat-input
                padding: 10px 20px
                border: 1px solid $border
            label:not(.mat-no-float):not(.mat-container-ignore),
            .mat-placeholder
                display: none
        mat-input-container.mat-input-focused label:not(.mat-no-float),
        mat-input-container.mat-input-has-placeholder label:not(.mat-no-float),
        mat-input-container.mat-input-has-value label:not(.mat-no-float)
            display: none
    &__error
        padding-top: 5px
        font-size: 14px
        color: $red
        position: absolute
        +left(0)
        top: 100%
        margin-top: -5px
        +right(0)
        &.ng-valid-custom-required
            display: none
        &.ng-invalid-custom-required
            display: block
        &:empty
            display: none
    &_nolabel &__label
        +m
            display: none
