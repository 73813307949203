.center
    max-width: 1380px
    margin: 0 auto
    padding: 0 30px
    &_xl
        max-width: 1260px
    &_default
        max-width: 1084px
    &_error
        max-width: 1085px
    &_blog
        max-width: 1024px
    +t
        max-width: none
        width: auto
        &_default
            max-width: none
            width: auto
    +m
        padding: 0 10px
        width: auto

