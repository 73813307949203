.mdc-floating-label
    max-width: 100%

.mat-mdc-form-field-error
    font-size: 14px

.mat-form-field-appearance-fill
    .mat-mdc-select
        .mat-mdc-select-arrow-wrapper
            transform: none

.mat-mdc-form-field
    // &.ng-invalid
    //     margin-bottom: 40px
    .mdc-text-field
        overflow: visible

.mat-mdc-form-field,
.mat-mdc-floating-label,
.mat-mdc-form-field-subscript-wrapper
    letter-spacing: normal
    font-size: 14px
    line-height: 1.125

.mat-form-field-hide-placeholder
    .mat-mdc-input-element.field__input
        &::-webkit-input-placeholder
            color: #333 !important

.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-mdc-floating-label-wrapper .mat-mdc-floating-label
    transform: none
    width: 100%

.mat-form-field-can-float.mat-form-field-should-float
    .mat-mdc-floating-label
        transform: none
        width: 100%

.mdc-text-field--filled:not(.mdc-text-field--disabled)
    background: transparent !important

mat-form-field.mat-form-field_styled
    display: block
    margin-bottom: 10px
    position: relative
    .mat-error
        &.mat-error_styled
            background-color: #EE2231
            padding: 12px 24px
            font-weight: 500
            font-size: 16px
            letter-spacing: normal
            border-radius: 8px
            color: #fff
            margin-top: 0
            line-height: 1.75
    &.mat-primary.mat-focused
        .mat-mdc-select-arrow
            color: $orange
            .nurse &
                color: $purple
            .petcare &
                color: $green
            .tutor &
                color: $bluet
            .housekeeper &
                color: $blue
            .driver &
                color: $cryola
            .cook &
                color: $bondi
    &.mat-focused
        .mat-mdc-form-field-flex
            .nurse &
                border-color: $purple
            .petcare &
                border-color: $green
            .tutor &
                border-color: $bluet
            .housekeeper &
                border-color: $blue
            .driver &
                border-color: $cryola
            .cook &
                border-color: $bondi
    .mdc-text-field
        padding: 0
    .mat-mdc-form-field-error-wrapper
        position: relative
        padding: 0
    .mat-mdc-form-field-bottom-align::before
        display: none
    .mat-mdc-form-field-focus-overlay
        display: none
    .mdc-text-field
        border-radius: 0
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label
        top: -32px
        // left: 0
        +left_c(0)
        // top: 0
    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label
        pointer-events: auto
        transform: none
        color: #333
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix
        padding: 0
        min-height: 0
    .mat-mdc-floating-label.mdc-floating-label
        line-height: 1.125
    .mat-form-field__label
        font-weight: 500
        color: #000
    .mat-mdc-text-field-wrapper
        padding: 0
        padding-top: 31px
        padding-bottom: 1.25em
        position: relative
    .mat-mdc-form-field-flex
        border: 1px solid #ccc
        background-color: #fff
        border-radius: 4px
        width: 100%
        position: relative
        padding: 0 12px
        min-height: 40px
        +align(center)
        +di-flex
        transition: .4s all
    textarea
        overflow-x: hidden
    .mat-mdc-form-field-infix
        +di-flex
        margin: 0
        width: auto
        flex-grow: 1
        flex-shrink: 1
        padding: 0
        border-block-start: none
        position: static
        border-top: none
        .mat-placeholder-required
            +margin-left(-.2em)
        .mat-mdc-input-element
            line-height: 1.5
            +placeholder-color(#a6a6a6)
            &.mat-chip-input
                +padding-left(10px)
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button
                -webkit-appearance: none
                margin: 0
        .mat-mdc-floating-label-wrapper
            top: -37px
            padding-top: 0
            .mat-mdc-floating-label
                display: block
                width: 100%
                color: #000
                transform: none
                top: 0
                &.mat-form-field-empty
                    display: inherit
    .mdc-line-ripple
        display: none
    .field__area.mat-mdc-input-element
        margin: 7px 0
        line-height: 1.7
        +placeholder-color(#999)
    .mat-mdc-form-field-subscript-wrapper
        position: absolute
        box-sizing: border-box
        width: 100%
        overflow: hidden
        margin-top: 0.54167em
        top: calc(100% - 1.66667em)
        &::before
            display: none
    .field__area_h68
        height: 68px
    .field__area_h140
        height: 160px
    .mat-form-field-underline
        display: none
    .mat-mdc-input-element
        margin: 0
        display: block
        font-size: 14px
        &.field__hidden
            display: none
    textarea.mat-mdc-input-element
        padding: 5px 0
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix
        padding: 0
        width: 38px
        height: 38px
        +di-flex
        +align(center)
        +jc(center)
        +margin-left(-6px)
        +margin-right(6px)
        border-radius: 0
        > .mat-icon
            padding: 0
            color: #333
            +di-flex
            +align(center)
            +jc(center)
            width: 100%
            &.active
                &.mat-icon_eye
                    color: #ff5722
                    stroke: #ff5722
            &.mat-icon_eye
                stroke: #000
                cursor: pointer
                width: 21px
                height: 21px
    .mat-mdc-form-field-icon-suffix
        width: auto
        +margin-left(0)
        +margin-right(0)
    .mat-form-field__label
        font-size: 16px
        &:after
            display: none
    .mat-form-field__label_fz18
        font-size: 18px
        line-height: 1.32
        font-weight: normal
    &.mat-form-field-invalid
        .mat-mdc-floating-label.mat-accent,
        .mat-mdc-floating-label .mat-form-field-required-marker,
        .mat-form-field__label
            color: #D0021B
    &.mat-focused,
    .mat-focused
        .mat-mdc-floating-label.mat-accent,
        .mat-mdc-floating-label,
        .mat-mdc-floating-label .mat-form-field-required-marker
            color: #262626
            .housekeeper &,
            .tutor &,
            .petcare &,
            .nurse &
                color: #262626
    .mat-error
        color: #D0021B
        margin-top: -1px
        &-filters
            margin-top: 24px
    .mat-form-field__show
        cursor: pointer
    &.mat-focused
        .mat-mdc-form-field-flex
            border-color: $orange
            .housekeeper &
                border-color: $bluet
            .tutor &
                border-color: $blue
            .nurse &
                border-color: $purple
            .petcare &
                border-color: $green
            .driver &
                border-color: $cryola
    &.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-mdc-floating-label-wrapper .mat-mdc-floating-label
        transform: none
    &.mat-form-field_home
        .mat-mdc-form-field-infix .mat-mdc-floating-label-wrapper
            +left_c(12px)
            .mat-form-field-required-marker
                display: none
        .mat-mdc-form-field-infix .mat-mdc-floating-label-wrapper .mat-mdc-floating-label
            cursor: pointer
            font-weight: normal
            color: #8c8c8c
        .mat-mdc-text-field-wrapper
            padding: 0
        .mat-mdc-form-field-flex
            min-height: 34px
    &.mat-form-field_chips
        .mat-mdc-form-field-flex
            padding-top: 8px
            padding-bottom: 8px
    &.mat-form-field_search
        padding-top: 10px
        margin-bottom: 0
        .mat-mdc-floating-label
            font-weight: normal
            line-height: 1.2
        .mat-form-field__label
            font-size: 16px
        .mat-mdc-form-field-flex
            border: none
            box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.08)
            border-radius: 4px
            min-height: 32px
        .mat-mdc-text-field-wrapper
            padding-bottom: 0
        .mat-mdc-form-field-icon-prefix
            width: 14px
            height: 14px
            +margin-left(0)
            +margin-right(16px)
            .mat-icon
                color: $orange
                width: 14px
                height: 14px
                .nanny &
                    color: $orange
                .housekeeper &
                    color: $bluet
                .tutor &
                    color: $blue
                .nurse &
                    color: $purple
                .petcare &
                    color: $green
                .driver &
                    color: $cryola
                .cook &
                    color: $bondi
        .mat-mdc-input-element
            font-size: 14px
    &.mat-form-field_code
        .mat-mdc-form-field-flex
            [dir="rtl"] &
                flex-direction: row-reverse
        .mat-mdc-form-field-icon-prefix
            display: flex
            align-items: center
            background-color: rgba(45, 156, 219, 0.04)
            width: auto
            height: 38px
            // flex: 0 0 72px
            flex: 0 0 auto
            justify-content: flex-start
            +margin-right(10px)
            +margin-left(-11px)
            [dir="rtl"] &
                +margin-left(10px)
                +margin-right(-11px)
            &.mat-form-field__code
                +di-flex
    &.mat-form-field_value
        .mat-mdc-form-field-flex
            border-color: $orange
    &.mat-form-field_error
        .mat-mdc-form-field-flex
            border-color: #EE2231
    &.mat-form-field_disabled
        opacity: .5
    &.mat-form-field_nomargin
        margin-bottom: 0
    &.mat-form-field_h44
        .mat-mdc-form-field-flex
            min-height: 44px
    &.mat-form-field_border
        .mat-mdc-text-field-wrapper
            padding-top: 37px
        .mat-mdc-floating-label-wrapper
            top: -32px
        .mat-mdc-form-field-flex
            border-color: #000
    &.mat-form-field_button
        .mat-mdc-input-element
            margin-bottom: 70px
    +dd
        .field__area_h140
            height: 124px
        &.mat-form-field_border
            .mat-mdc-input-element
                font-size: 16px

mat-form-field.mat-form-field_inline
    text-transform: lowercase
    .mat-mdc-form-field-subscript-wrapper
        display: none
    .mdc-text-field
        padding: 0
        background-color: rgba(0, 0, 0, 0.18) !important
        padding: 8px
        padding-bottom: 5px
        padding-top: 3px
        border-radius: 8px
        width: auto
        +d
            margin: 0 6px
            margin-top: -1px
    .mat-mdc-select-arrow-wrapper
        display: none
    .mdc-line-ripple
        display: none
    .mat-mdc-select-value
        color: #fff
    .mdc-text-field--filled:not(.mdc-text-field--disabled)
        background: none
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
        .mat-mdc-form-field-infix
            padding: 0
            min-width: 100px
            width: auto
            border: none
            min-height: 0
        .mat-mdc-text-field-wrapper
            padding: 0
        .mat-form-field-underline
            display: none

mat-form-field.mat-form-field_gray2
    .mat-mdc-form-field-flex
        border-color: #ccc
        background-color: #fff
    .mat-mdc-form-field-infix
        .mat-mdc-input-element
            +placeholder-color(rgba(0,0,0,.4))
            opacity: 1

mat-form-field.mat-form-field_light
    .mat-mdc-form-field-icon-prefix
        .mat-icon
            color: $orange
    .mat-form-field-suffix
        .mat-icon
            color: #73D658
            width: 19px
            height: 19px
    .mat-mdc-form-field-flex
        border-color: #cacacc
        background-color: #fcfdff
    .mat-mdc-form-field-infix
        .mat-mdc-input-element
            +placeholder-color(rgba(0,0,0,.4))
    .mat-mdc-form-field-subscript-wrapper
        position: relative
        top: -18px
        margin-top: 0
    .mat-mdc-form-field-error
        margin-top: 8px
        margin-bottom: 0
        background-color: #EE2231
        font-size: 14px
        color: #fff
        letter-spacing: normal
        font-weight: 500
        +di-flex
        +jc(space-between)
        border-radius: 4px
        padding: 13px 9px
        +padding-left(12px)
        &__close
            background-color: #fff
            border-radius: 50%
            overflow: hidden
            width: 24px
            height: 24px
            flex: 0 0 24px
            +margin-left(10px)
            +di-flex
            +align(center)
            +jc(center)
            position: relative
            top: -2px
            cursor: pointer
        .mat-icon
            width: 14px
            height: 14px
            flex: 0 0 14px
            transform: rotate(45deg)
            stroke: #EE2231

mat-form-field.mat-form-field_dummy
    .mat-mdc-text-field-wrapper
        padding: 0
    .mat-mdc-form-field-infix
        width: auto
        padding: 0
        border: none

mat-form-field.mat-form-field_noborder
    .mat-mdc-form-field-flex
        +padding-left(0)
        +padding-right(0)
        border: none

mat-form-field.mat-form-field_color
    .mat-mdc-form-field-flex
        border-color: #ffeee9
        .tutor &
            border-color: $blue_bg
        .petcare &
            border-color: $green_bg
        .nurse &
            border-color: $purple_bg
        .housekeeper &
            border-color: $bluet
        .driver &
            border-color: #e4e7f5
        .cook &
            border-color: #cceaed
    .mat-mdc-form-field-icon-prefix
        background-color: $orange_bg
        text-transform: uppercase
        letter-spacing: normal
        font-size: 16px
        +margin-left(-12px)
        min-width: 48px
        width: auto
        padding: 0 5px
        .tutor &
            background-color: $blue_bg
        .petcare &
            background-color: $green_bg
        .nurse &
            background-color: $purple_bg
        .housekeeper &
            background-color: $bluet_bg
        .driver &
            background-color: $cryola_bg
        .cook &
            background-color: $bondi_bg

mat-form-field.mat-form-field_shadow2
    .mat-mdc-form-field-flex
        border: 1px solid #E6E6E6
        box-shadow: var(--shadow_main)
        .tutor &
            border-color: $blue_bg
        .petcare &
            border-color: $green_bg
        .nurse &
            border-color: $purple_bg
        .housekeeper &
            border-color: $bluet_bg

.mat-form-field-disabled
    opacity: .4


mat-form-field.mat-form-field_shadow
    .mat-form-field__label
        font-size: 16px
    .mat-mdc-form-field-flex
        border: none
        box-shadow: 4px 8px 32px 0 rgba(0, 0, 0, 0.08)
    .mat-select
        .mat-select-placeholder
            color: #000
            font-size: 14px
            letter-spacing: normal

mat-form-field.mat-form-field_noborder
    display: block
    .mat-form-field-underline
        display: none
    .mat-mdc-form-field-infix
        border: none
        padding: 0

mat-form-field.mat-form-field_gray
    .mat-mdc-form-field-flex
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.15)
        border-radius: 3px
        background-color: #f9f9f9
        border: none
        min-height: 32px
    .mat-mdc-form-field-icon-prefix
        width: 32px
        height: 32px
        padding: 9px
        +margin-right(0)
        .mat-icon
            color: #757575

mat-form-field.mat-form-field_address
    .mat-mdc-form-field-flex
        +padding-left(0)
        +padding-right(0)

mat-form-field.mat-form-field_postalcode
    margin: 0
    .mat-mdc-text-field-wrapper
        padding: 0
    .mat-mdc-floating-label-wrapper
        display: none
    .mat-mdc-form-field-flex
        box-shadow: var(--shadwo_main)
        border-radius: 200px
        border: 1px solid #6A93AD
        padding: 11px 24px
    .mat-mdc-input-element
        font-size: 20px
        line-height: 1.44
        letter-spacing: normal

.mat-error
    margin-top: 0.54167em
    padding-top: 0.5px
    color: #D0021B
    font-size: 14px

.create
    .mat-form-field-appearance-legacy .mat-mdc-form-field-subscript-wrapper
        position: relative
        top: 0
        margin-top: 21px

mat-form-field.mat-form-field_nolabel
    .mat-mdc-floating-label
        display: none
    .mat-mdc-text-field-wrapper
        padding-top: 0
        padding-bottom: 0
    .mat-mdc-form-field-subscript-wrapper
        top: 100%

mat-form-field.mat-form-field_h428
    margin-bottom: 0
    .mat-mdc-form-field-flex
        padding-bottom: 80px
    .mat-mdc-form-field-infix,
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix
        min-height: 348px

mat-form-field.mat-form-field_fz16
    .field__area.mat-mdc-input-element
        margin: 5px 0
        font-size: 16px
        line-height: 1.24

mat-form-field.mat-form-field_blue
    .mat-mdc-form-field-flex
        border: none
        background-color: rgba(45, 156, 219, 0.04)
        padding: 6px 12px
        .mat-select
            font-size: 14px

mat-form-field.mat-form-field_mb
    .mat-mdc-text-field-wrapper
        padding-bottom: 1.125rem

mat-form-field.mat-form-field_login
    .mat-mdc-form-field-icon-prefix
        +margin-left(-12px)
        +margin-right(12px)
        .mat-icon
            color: $orange
            .mat-icon
                color: $orange

.mat-form-field__placeholder
    color: rgba(0,0,0,.4)

.address-input
    &__input.mat-form-field_styled
        .mat-mdc-text-field-wrapper
            z-index: 10000

.address-input
    &__input.mat-form-field_styled
        .mat-mdc-text-field-wrapper
            position: static
