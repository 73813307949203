.search-map-pin
    display: block
    border: 2px solid #6A93AD
    padding: 0
    margin: 0
    background: #fff
    cursor: pointer
    overflow: hidden
    border-radius: 50%
    // box-shadow: 0 4px 64px rgba(0, 0, 0, .06)
    white-space: normal
    &_count
        border-color: $orange
        &_1
            border-width: 1px
            font-size: 9px
        &_2
            border-width: 1px
            font-size: 12px
        &_3
            border-width: 2px
            font-size: 15px
        &_4
            border-width: 2px
            font-size: 18px
        &_5
            border-width: 2px
            font-size: 21px
        &_6
            border-width: 2px
            font-size: 24px
        &_7
            border-width: 3px
            font-size: 27px
        &_8
            border-width: 3px
            font-size: 30px
        &_9
            border-width: 3px
            font-size: 33px
        &_10
            border-width: 4px
            font-size: 36px
    &__image
        display: block
        width: 100% !important
        height: 100% !important
        object-fit: cover
    &__placeholder
        font-size: 13px
        padding-top: 1px
        width: 100%
        height: 100%
        flex: 0 0 100%
        text-transform: uppercase
        font-weight: bold
        color: #fff
        display: flex
        align-items: center
        justify-content: center
        text-align: center
        // padding-bottom: 2px
    &__digit
        font-family: $font
        width: 100%
        height: 100%
        +di-flex
        +jc(center)
        +align(center)
        font-weight: bold
        color: $orange
        letter-spacing: normal
        position: relative
        top: -1px

.leaflet-item-popup
    width: 345px
.leaflet-item-popup .leaflet-popup-content
    margin: 0
.leaflet-item-popup .leaflet-popup-tip
    width: 0
    height: 0
