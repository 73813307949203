.m-hide
    +m
        display: none

.m-show
    display: none
    +m
        display: block
    &-inline
        display: none
        +m
            display: inline

.m-footer
    background: #FFF
    z-index: 70
    position: fixed
    +left(0)
    +right(0)
    bottom: 100px
    padding: 0 30px
    padding-bottom: 30px
    background: none
    height: auto
    button.mat-mdc-raised-button
        width: 100%
    +d
        display: none
