=rtl
    :host-context([dir="rtl"]) &
        @content

=ltr
    :host-context([dir="ltr"]) &
        @content

=rtl
    :host-context([dir="rtl"]) &
        @content

=ltr
    :host-context([dir="ltr"]) &
        @content

=i5
    @media screen and (max-width: "320px")
        @content

=m
    @media screen and (max-width: "750px")
        @content
=ml
    @media screen and (max-width: "750px") and (orientation: landscape)
        @content

=t
    @media screen and (min-width: "751px") and (max-width: "1140px")
        @content

=up
    @media screen and (max-height: "681px") and (min-width: "751px")
        @content

=dd
    @media screen and (min-width: "751px")
        @content

=desktop
    @media screen and (min-width: "1000px")
        @content

=min-width($size)
    @media screen and (min-width: $size)
        @content

=ipadpro
    @media only screen and (min-device-width: 768px)  and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none)
        @content

=d
    @media screen and (min-width: "1141px")
        @content

=ios
    @supports (-webkit-overflow-scrolling: touch)
        @content

=iospad
    @supports (-webkit-overflow-scrolling: touch)
        @media screen and (min-width: "751px")
            @content

=print
    @media print
        @content

=print_dd
    +print
        @content
    +dd
        @content

=bs
    box-shadow: 0 1px 4px rgba(#000,.5)

=gr($direction)
    background: linear-gradient(to $direction, #2398EC, #0073F9)

// width
=r($width)
  @media only screen and (max-width: $width + "px")
    @content

=text-overflow
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

=vertical
  text-align: center
  font-size: 0
  &:before
    content: ""
    display: inline-block
    vertical-align: middle
    width: 0
    height: 100%

=vert
  font-size: 0
  &:before
    content: ""
    display: inline-block
    vertical-align: middle
    width: 0
    height: 100%

=clr
  &:after
    content: " "
    display: table
    clear: both
    zoom: 1

=placeholder-color($color)
  &:-moz-placeholder,
  &::-moz-placeholder
    color: $color
    opacity: 1
  &::-webkit-input-placeholder
    color: $color

=gray
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale")
  filter: gray
  -webkit-filter: grayscale(100%)
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1)
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(grayScale=1)"

=gray-zero
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale")
  -webkit-filter: grayscale(0%)

=border-color($color, $opacity: 0.3)
    border-color: $color
    border-color: rgba($color, $opacity)

=wb
    overflow-wrap: break-word
    word-wrap: break-word
    word-break: break-word
    hyphens: auto

=di-flex()
    display: -webkit-box
    display: -moz-box
    display: -ms-flexbox
    display: -webkit-flex
    display: flex

=jc($value)
    -webkit-box-pack: justify
    -moz-box-pack: justify
    -ms-flex-pack: justify
    -webkit-justify-content: $value
    justify-content: $value

=align($value)
    -webkit-box-align: $value
    -moz-box-align: $value
    -ms-flex-align: $value
    -webkit-align-items: $value
    align-items: $value

=scrollbar
  &::-webkit-scrollbar
      width: 10px
  &::-webkit-scrollbar-track
      background: #f1f1f1
  &::-webkit-scrollbar-thumb
      background: #888
  &::-webkit-scrollbar-thumb:hover
      background: #555

=custom-scrollbar($w: 13px)
    &::-webkit-scrollbar
        width: $w
        padding: 0 52px
    &::-webkit-scrollbar-thumb
        background: #CCDCF3 url('/assets/img/common/slider-thumb.svg') no-repeat 50% 50%
        min-height: 80px
        border-radius: 30px
        cursor: pointer
    &::-webkit-scrollbar-thumb:hover
        background-color: #CCDCF3
    &::-webkit-scrollbar-track
        background: #F5F8FD
        border-radius: 30px
        box-shadow: inset 0 0 0 0 #F0F0F0

=custom-scrollbar-hor($h: 12px)
    &::-webkit-scrollbar
        height: $h
        width: $h
        padding: 0 52px
    &::-webkit-scrollbar-thumb
        background: #CCDCF3 url('/assets/img/common/slider-thumb_hor.svg') no-repeat 50% 50%
        min-height: 80px
        border-radius: 30px
        cursor: pointer
    &::-webkit-scrollbar-thumb:hover
        background-color: #CCDCF3
    &::-webkit-scrollbar-track
        background: #F5F8FD
        border-radius: 30px
        box-shadow: inset 0 0 0 0 #F0F0F0

=margin-left($mr: auto)
    -webkit-margin-start: $mr
    -moz-margin-start: $mr
    margin-inline-start: $mr

=margin-right($mr: auto)
    -webkit-margin-end: $mr
    -moz-margin-end: $mr
    margin-inline-end: $mr

=padding-left($pd: auto)
    -webkit-padding-start: $pd
    -moz-padding-start: $pd
    padding-inline-start: $pd

=padding-right($pd: auto)
    -webkit-padding-end: $pd
    -moz-padding-end: $pd
    padding-inline-end: $pd

=left($l: auto)
    [dir="ltr"] &
        left: $l
    :host-context([dir="ltr"]) &
        left: $l
    [dir="rtl"] &
        right: $l
    :host-context([dir="rtl"]) &
        right: $l

=right($r: auto)
    [dir="ltr"] &
        right: $r
    :host-context([dir="ltr"]) &
        right: $r
    [dir="rtl"] &
        left: $r
    :host-context([dir="rtl"]) &
        left: $r

=left_c($l: auto)
    [dir="ltr"] &
        left: $l
    [dir="rtl"] &
        right: $l

=right_c($r: auto)
    [dir="ltr"] &
        right: $r
    [dir="rtl"] &
        left: $r

@function calculateColors($color, $opacity)
    @return mix(rgb(red($color), green($color),blue($color)), white, $opacity)
