.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper
    transform: none

.mat-mdc-select
    background-color: #fafafa
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)
    font-size: 16px
    line-height: 1.43
    .mat-mdc-select-value
        color: #000
    .mat-select-placeholder
        color: #9a9a9a
        font-size: 16px
    &.mat-select_big
        height: 40px
        line-height: 40px
        margin-bottom: 28px
    &.mat-select_outline
        height: 36px
        line-height: 36px
        margin-bottom: 0
        display: inline-block
        vertical-align: middle
        width: 215px
        background: none
        box-shadow: none
        border: 1px solid #fff
        border-radius: 4px
        padding: 0 10px
        .mat-mdc-select-value
            color: #fff
    &.mat-select_material
        box-shadow: none
        background-color: transparent
    &.mat-select_material2
        box-shadow: none
        background-color: transparent
        .mat-select-placeholder
            color: #9a9a9a
            font-size: 14px
    &.mat-select_shadow
        border: none
        background: #FFFFFF
        box-shadow: var(--shadow_main)
        border-radius: 4px
        font-size: 14px
        letter-spacing: normal
        position: relative
        padding: 9px 16px
        cursor: pointer
    &.mat-select_inline
        background: none
        box-shadow: none
        text-transform: lowercase
        .mat-mdc-select-value
            color: #fff
            overflow: visible
        .mat-mdc-select-arrow-wrapper
            display: none
        .mat-mdc-select-arrow
            color: #fff
    &.mat-select_sort
        background: none
        box-shadow: none
        width: auto
        +padding-right(18px)
        position: relative
        .mat-mdc-select-trigger
            display: inline-flex
            align-items: center
        // &::after
        //     width: 0
        //     height: 0
        //     border-style: solid
        //     border-width: 6px 4px 0 4px
        //     border-color: #ffffff transparent transparent transparent
        //     position: absolute
        //     +right(0)
        //     top: 50%
        //     margin-top: -3px
        .mat-mdc-select-value
            color: #fff
            text-decoration: underline
            max-width: none
        .mat-mdc-select-arrow-wrapper
            position: relative
            top: 2px
            margin-left: 0.5em
            // display: none
        .mat-mdc-select-arrow
            color: #fff
            border-width: 6px 4px 0 4px
    &.mat-select_nobg
        background: none
        box-shadow: none
        font-size: 16px
        line-height: 1.24
        letter-spacing: normal
        min-width: 100px
        width: auto
        .mat-mdc-select-value
            color: currentColor
            width: calc(100% - 20px)
            +padding-right(7px)
            max-width: none
        .mat-mdc-select-trigger
            height: 20px
            line-height: 20px
            display: flex
            align-items: center
        .mat-mdc-select-arrow-wrapper
            display: flex
            align-items: center
            width: 20px
            height: 20px
            flex: 0 0 20px
            border: 1px solid currentColor
            border-radius: 50%
            overflow: hidden
            text-align: center
        .mat-mdc-select-arrow
            margin: 0 auto
            width: 8px
            height: 8px
            border: none
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.125 1.25L8 8.125L14.875 1.25' stroke='currentColor' stroke-width='1.65' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
            background-repeat: no-repeat
            background-size: 8px
            background-position: 50% 50%

mat-select.mat-default-theme .mat-mdc-select-value.mat-select-placeholder,
mat-select .mat-mdc-select-value.mat-select-placeholder,
mat-select.mat-default-theme.mat-no-underline .mat-mdc-select-value,
mat-select.mat-no-underline .mat-mdc-select-value
    padding-top: 0
    +padding-right(10px)
    padding-bottom: 0
    padding-bottom: 20px
    background: #FAFAFA
    border-radius: 2px
    border: none !important
    box-shadow: 0 0 2px rgba(#000, .12), 0 2px 2px rgba(#000, .24)
    font-size: 16px
    color: #000

.mat-select-panel
    background-color: #fff
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)

.mat-select-content
    background: #fff

.mat-mdc-select.lang-selector
    background: #1D5E9F
    box-shadow: none
    border-radius: 200px
    display: block
    height: 48px
    .mat-mdc-select-trigger
        height: 48px
    .mat-mdc-select-value
        +padding-left(60px)
        position: relative
        height: 48px
        vertical-align: middle
        color: #fff
        font-size: 18px
        letter-spacing: normal
        line-height: 1.32
        display: flex
        align-items: center
        &::before
            content: ''
            display: block
            width: 48px
            height: 48px
            position: absolute
            +left(0)
            top: 0
            border-radius: 50%
            z-index: 1
            background-color: #fff
        &::after
            content: ''
            display: block
            position: absolute
            +left(12px)
            top: 12px
            width: 24px
            height: 24px
            background-image: url('/assets/img/common/lang-selector.svg')
            background-size: cover
            z-index: 2
    .mat-mdc-select-arrow-wrapper
        padding: 8px
    .mat-mdc-select-arrow
        width: 32px
        height: 32px
        border: none
        border-radius: 50%
        background-color: #336EA8
        background-image: url('/assets/img/common/lang-arrow.svg')
        background-repeat: no-repeat
        background-position: 50% 50%
        svg
            display: none
