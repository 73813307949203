.form
    padding-top: 20px
    +padding-left(50px)
    +padding-right(50px)
    padding-bottom: 30px
    background: #FFF
    +m
        padding-top: 30px
        +padding-left(15px)
        +padding-right(15px)
        padding-bottom: 45px
    &__title
        font-size: 26px
        font-weight: 800
        margin-bottom: 5px
        +rtl
            font-weight: 700
    &__row
        margin: 0 -10px
        font-size: 0
    &__col
        display: inline-block
        width: 50%
        padding: 0 10px
        font-size: 16px
        vertical-align: top
        &.m-show
            display: none
        +m
            width: 100%
            display: block
            margin-bottom: 10px
            &.m-show
                display: block
            &.m-hide
                display: none
        &_lg
            width: 100%
    &__foot
        padding-top: 5px
        text-align: center
        +m
            padding-top: 40px
        &-btn,
        &-btn-icon
            &.mat-mdc-button
                margin: 10px
