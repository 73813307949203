$orange: #ff5722
$blue: #2F80ED
$yellow: #FFCC01
$border: #9B9B9B
$red: #D0021B
$gray: #4a4a4a
$green: #219653
$bluet: #90CAF9
$purple: #902DEC
$cryola: #7986CB // driver
$bondi: #0097a7 // cook
$color_bg: rgba(255, 87, 34, 0.04)
$dove: #6A93AD
$color_bg2: rgba(255, 87, 34, 0.02)

$orange_bg: #FFF8F6
$green_bg: rgba(33, 150, 83, 0.04)
$bluet_bg: #fbfdff
$purple_bg: rgba(144, 45, 236, 0.04)
$blue_bg: #f7fafe
$bondi_bg: #f5fbfb
$cryola_bg: #fafafd
