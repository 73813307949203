.dialog
    .mdc-dialog
        &__title
            &::before
                display: none

mat-dialog-container
    .mdc-dialog__container
        width: 100%
    &.mat-mdc-dialog-container
        --mdc-dialog-subhead-font: #{$font}
        height: initial
        padding: 0
        max-width: none
        border-radius: 4px
        overflow: hidden
        color: #000
        background-color: #fff
        .mdc-dialog__surface
            background: none
    .mdc-dialog__content
        --mdc-dialog-supporting-text-font: #{$font}
        --mdc-dialog-supporting-text-color: #000
        --mdc-dialog-supporting-text-line-height: 1.6
        --mdc-dialog-supporting-text-tracking: 0.01em
        padding: 30px 24px
        margin: 0
        background: #fff
        max-height: calc(100vh - 84px)
    .mat-dialog-title
        margin-bottom: 0
        padding: 12px 0
        text-transform: uppercase
        font-weight: bold
        font-size: 24px
        &.m-hide
            display: block
        &.m-show
            display: none
    +m
        .mat-mdc-dialog-content
            background: #fff
            max-height: none
            padding: 0 21px
            padding-bottom: 21px
            padding-top: 18px
            flex-grow: 1
            flex-shrink: 1
            position: relative
            overflow: hidden
        &.mat-mdc-dialog-container
            position: absolute
            +left(0)
            +right(0)
            top: 0
            bottom: 0
            margin: 0
            overflow: hidden
            background: #fff
            border-radius: 0
            padding: 0
            +di-flex
            flex-direction: column
            .mat-datepicker-content
                background: transparent
                margin: 0
                max-height: 100vh
                display: flex
                justify-content: center
                align-items: center
            .mat-datepicker-dialog &
                background: transparent
            > *
                display: flex
                flex-grow: 1
                flex-shrink: 1
        .mat-dialog-title
            &.m-hide
                display: none
            &.m-show
                display: block

.cdk-global-overlay-wrapper .cdk-overlay-backdrop.cdk-overlay-backdrop-showing
    opacity: 0.78
.cdk-overlay-backdrop_hide
    background-color: transparent
.cdk-overlay-backdrop_filters
    top: $header_height
    background: rgba(0, 0, 0, 0.32)
.cdk-overlay-backdrop_sheet
    background: rgba(0, 0, 0, 0.32)
    .cdk-overlay-pane
        width: 100%
.cdk-overlay-backdrop_notifications
    top: $header_height
    background: rgba(206, 213, 224, .6)
    opacity: .6
.cdk-overlay-container
    // z-index: 2147483648
    z-index: 2147483646
    &_filter
        z-index: 1201
        .mat-mdc-dialog-container
            box-shadow: none
            border-radius: 0
            background: none
        .cdk-overlay-pane
            max-width: 100vw !important
            margin-top: $header_height !important
        .cdk-overlay-backdrop
            top: $header_height
    +t
        &_filter
            .cdk-overlay-pane
                overflow: hidden
                margin-top: $header_height !important
            .cdk-overlay-backdrop
                top: $header_height
    +m
        &_filter
            z-index: 2147483648
        .cdk-overlay-backdrop
            top: 0

.cdk-overlay-pane.cdk-overlay-pane_nopadding
    mat-dialog-container .mat-mdc-dialog-content
        padding: 0

.cdk-overlay-container.cdk-overlay-container_nointernet
    z-index: 2147483647

// все стили панелей должны быть в компоненте попапа, но у нас нет компоненты попапа
.filterPanel
    max-width: none
    mat-dialog-container
        box-shadow: none
        border-radius: 0
    .dialog
        max-height: 100%
    .mat-mdc-dialog-content
        padding: 0
        max-height: none
        height: 100vh
        overflow: hidden
        background: none
    +d
        --mat-dialog-content-padding: 0
        mat-dialog-container
            .dialog
                max-height: none
                .mat-mdc-dialog-content
                    max-height: calc(100vh - 64px)
                    height: auto
                    // height: calc(100vh - 64px)
                    overflow: hidden
    +iospad
        mat-dialog-container
            .dialog
                max-height: none
                .mat-mdc-dialog-content
                    max-height: calc(100vh - 91px)
    +m
        mat-dialog-container
            .dialog
                max-height: none
                .mat-mdc-dialog-content
                    max-height: none
                    height: auto
                    min-height: 0

.inlinePanel
    mat-dialog-container
        box-shadow: 6px 12px 92px rgba(0, 0, 0, 0.0790874)
        border-radius: 8px

.recommPanel
    mat-dialog-container
        .mat-mdc-dialog-content
            padding: 30px
            width: 520px
            +m
                width: auto

.registerPanel
    .mat-mdc-dialog-container
        box-shadow: var(--shadow_main)
    +m
        mat-dialog-container.mat-mdc-dialog-container
            background: none

.bottomPanel
    .mat-mdc-dialog-container
        box-shadow: none
        background: none
        top: auto
    +m
        mat-dialog-container.mat-mdc-dialog-container
            background: none

.phonePanel
    +m
        mat-dialog-container.mat-mdc-dialog-container
            height: 450px
            top: auto
            bottom: 0

.documentPanel
    mat-dialog-container.mat-mdc-dialog-container
        border-radius: 0

.dialog_round
    mat-dialog-container.mat-mdc-dialog-container
        box-shadow: 2px 4px 124px rgba(0, 0, 0, 0.04)
        border-radius: 16px

+dd
    .dialog_bottom
        mat-dialog-container.mat-mdc-dialog-container
            box-shadow: 2px 4px 124px rgba(0, 0, 0, 0.04)
            border-radius: 16px

.cdk-overlay-backdrop_bottom
    background-color: rgba(0,0,0,.2)

.cdk-overlay-container_menu
    top: $header_height
    width: 320px
    +margin-right(calc((100vw - 1380px)/2))
    +margin-left(auto)
    mat-dialog-container.mat-mdc-dialog-container
        border-radius: 0
    .cdk-overlay-backdrop
        top: -$header_height
        background: none
    .mat-mdc-dialog-container
        box-shadow: 2px 16px 32px 0 rgba(0, 0, 0, 0.1)
    @media screen and (max-width: "1300px")
        +margin-right(0)
        +margin-left(auto)
        .cdk-global-overlay-wrapper
            mat-dialog-container.mat-mdc-dialog-container
                bottom: auto
                //max-height: calc(100vh - 60px)
    +m
        position: relative !important
        top: 0
        mat-dialog-container.mat-mdc-dialog-container
            position: relative
            box-shadow: 2px 16px 32px 0 rgba(0, 0, 0, 0.1)
        .cdk-global-overlay-wrapper
            +right(0)
        .cdk-overlay-backdrop
            top: -$header_height
        mat-dialog-container.mat-mdc-dialog-container > *
            display: block
        .dialog
            display: block

.cdk-overlay-container_notifications
    +dd
        top: $header_height
        width: 360px
        height: 100vh
        +margin-left(auto)
        &.mat-mdc-dialog-panel.cdk-overlay-pane
            max-width: 360px
        mat-dialog-container.mat-mdc-dialog-container
            border-radius: 0
        .cdk-overlay-backdrop
            top: -$header_height
            background: none
        .mat-mdc-dialog-container
            box-shadow: 2px 16px 32px 0 rgba(0, 0, 0, 0.1)
        @media screen and (max-width: "1300px")
            +margin-right(0)
            +margin-left(auto)
            .cdk-global-overlay-wrapper
                mat-dialog-container.mat-mdc-dialog-container
                    bottom: auto
    //+m
    //    position: relative !important
    //    top: 0
    //    mat-dialog-container.mat-mdc-dialog-container
    //        position: relative
    //        box-shadow: 2px 16px 32px 0 rgba(0, 0, 0, 0.1)
    //    .cdk-global-overlay-wrapper
    //        +right(0)
    //    .cdk-overlay-backdrop
    //        top: -$header_height
    //    mat-dialog-container.mat-mdc-dialog-container > *
    //        display: block
    //    .dialog
    //        display: block

.cdk-overlay-backdrop_register
    background: rgb(255,255,255)
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
    +m
        background-color: rgba(255,255,255,.5)

.menuPanel
    .cdk-overlay-backdrop
        display: none
    mat-dialog-container
        &.mat-mdc-dialog-container
            background: #FCFCFC
            box-shadow: 2px 16px 32px 0 rgba(0, 0, 0, 0.1)
            border-radius: 0
        .mat-mdc-dialog-content
            max-height: calc(100vh - #{$header_height})
        +ios
            max-height: 75vh
    +m
        .dialog
            height: auto

.cdk-overlay-container .mat-bottom-sheet-container
    background: none
    color: #333
    padding: 0
    box-shadow: none
    line-height: 1.42

.mat-bottom-sheet-container_sheet .mat-bottom-sheet-container,
.mat-bottom-sheet-container_bottom .mat-bottom-sheet-container
    +m
        padding: 0
    +dd
        position: absolute !important
        left: 0
        right: 0
        top: 0
        bottom: 0
        display: flex
        justify-content: center
        align-items: center
        min-width: 0
        max-width: none
        max-height: none
        pointer-events: none

.mat-bottom-sheet-container_bottom .mat-bottom-sheet-container
    +dd
        align-items: flex-end
        padding: 0
        justify-content: normal

.mat-bottom-sheet-container_nopad .mat-bottom-sheet-container
    +m
        padding-bottom: 0
        +padding-left(0)
        +padding-right(0)
    +dd
        padding-bottom: 0
        overflow: hidden

.mat-bottom-sheet-container_markdown .mat-bottom-sheet-container
    +dd
        max-height: 90vh
        height: 90vh
