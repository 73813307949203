.message
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2)
    padding: 12px 65px
    +padding-right(20px)
    background: #fff
    +jc(space-between)
    position: relative
    margin: 20px 0
    display: none
    &__icon
        text-align: center
        width: 28px
        height: 28px
        position: absolute
        +left(22px)
        top: 50%
        margin-top: -14px
    &__content
        flex-grow: 1
        font-size: 18px
    &__reload
        font-size: 16px
        display: block
        color: #1d73d4
        text-transform: uppercase
        text-align: end
        font-weight: bold
        letter-spacing: 0.5px
        padding-top: 1px
    +m
        flex-wrap: wrap
        &__icon
            top: 12px
            margin-top: 0
        &__content
            width: 100%
            margin-bottom: 5px
