@import '_vars'

*,
*:before,
*:after
    box-sizing: inherit

html, body
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    -webkit-touch-callout: none
    min-height: 100%
    -webkit-text-size-adjust: 100%
    -ms-text-size-adjust: 100%
    -moz-osx-font-smoothing: grayscale
    -webkit-font-smoothing: antialiased
    font-smoothing: antialiased
    font-weight: 400
    box-sizing: border-box
    position: relative
    text-decoration-skip-ink: none
    +m
        overflow: auto

html
    height: 100%
// word-break: break-word

body
    font-family: $font
    margin: 0
    padding: 0
    // min-width: 1240px
    font-size: 16px
    letter-spacing: 0.01em
    color: #000
    line-height: 1.6
    text-rendering: optimizeLegibility
    height: auto
    min-height: 100%
    overflow-y: scroll
    +ios
        overflow-y: scroll
        -webkit-overflow-scrolling: touch
    +m
        min-width: 320px
    +t
        min-width: 0

::selection
    background: $orange
    color: #fff
    text-shadow: none

::-moz-selection
    background: $orange
    color: #fff
    text-shadow: none

[tabindex='-1']:focus
    outline: none

.mat-tooltip-trigger
    touch-action: auto !important

@import "_leaflet-map"
@import '_normalize.css'

@import '_fonts'
@import '_sprite'
@import '_mobile'
@import 'blocks/_center'
@import 'blocks/_field'
@import 'blocks/_form'
// @import 'blocks/_calendar'
@import 'blocks/_messages'
@import 'blocks/_choice'
@import 'blocks/_page'
@import 'common'
