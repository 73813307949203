.choice
    margin-top: 0
    margin-inline: -5px
    margin-bottom: -10px
    margin-bottom: 0
    font-size: 0
    &__col
        display: inline-block
        width: 50%
        margin-bottom: 10px
        padding: 0 5px
        &_sm
            width: 25%
        &_lg
            width: 100%
        &_tr
            width: 33%
        +m
            width: 100%
    &__check
        display: block
        cursor: pointer
        @at-root span#{&}
            cursor: default
    &__check_icon &__title
        +padding-left(10px)
        +padding-right(40px)
        text-transform: none
        font-size: 13px
        text-align: start
    &__input
        display: none
    &__input:checked + &__title,
    &__input_checked + &__title
        background: $blue
        border-color: transparent
        color: #fff
        opacity: 1
        .mat-icon
            transform: rotate(45deg)
            stroke: #fff
        +m
            background: #ff5722
            border-color: #ff5722
    &__title
        display: block
        position: relative
        padding: 0 5px
        background: #ffffff
        border: solid 1px #818181
        vertical-align: top
        font-size: 11px
        color: #818181
        text-transform: uppercase
        user-select: none
        cursor: pointer
        +text-overflow
        transition: all .2s
        .mat-icon
            position: absolute
            top: 50%
            +right(15px)
            margin-top: -6px
            font-size: 12px
            transition: transform .2s
    &__check_active &__box
        border-color: #73d658
    &__subtitle
        display: block
        font-size: 12px
        letter-spacing: normal
        color: #73d658
    &__icon
        position: absolute
        top: 50%
        +right(15px)
        margin-top: -6px
        font-size: 12px
        transition: transform .2s
    &__pic
        max-width: 70%
        margin-bottom: 10px
        +m
            display: none
    &__pic-wrap
        width: 32px
        height: 32px
        border: 1px solid $orange
        border-radius: 50%
        overflow: hidden
        +di-flex
        +align(center)
        +jc(center)
        flex-grow: 0
        flex-shrink: 0
    &_img &__pic-wrap &__pic
        max-height: 22px
        margin: 0 auto
    &_full &__col
        width: 100%
    &_wide &__col,
    &_wide.choice_filters &__col
        &:nth-child(odd)
            &:last-child
                +m
                    width: 100%
        +r(374)
            width: 100%
    &_filters &__col
        &:nth-child(odd)
            &:last-child
                width: 100%
        +r(374)
            padding: 0 5px
    &_filters &__title
        font-size: 14px
        .mat-icon_close
            margin-top: -6px
    &_filters &__col
        +m
            padding: 0
    &_gradient &__title
        border-color: $border
        color: $gray
    &_gradient &__check:not(&__check_icon) &__title
        font-weight: 500
        font-size: 14px
        +m
            height: 35px
            border-color: #fff
            background: none
            font-weight: normal
            color: #FFF
            line-height: 33px
    &_gradient &__input:checked + &__title
        background: linear-gradient(to left top, #0095FF,#0065DB)
        +m
            border-color: $orange
            background: $orange
            color: #fff
    &_gradient &__check_md:not(&__check_icon) &__title
        height: 25px
        padding: 0 12px
        line-height: 23px
    &_wide &__col
        +m
            width: 100%
    &_cols &__col
        width: 33.33%
        +m
            width: 100%
    &_cols#{&}_wide &__col
        +m
            width: 100%
    &_material
        +margin-left(-10px)
        +margin-right(-10px)
    &_material.choice_img
        +margin-left(0)
        +margin-right(0)
    &_material &__col
        margin-bottom: 15px
        padding: 0 10px
    &_material &__title
        display: block
        position: relative
        border-radius: 50px
        background: #ffffff
        vertical-align: top
        font-size: 16px
        user-select: none
        cursor: pointer
        +text-overflow
        transition: all .2s
        padding: 11px
        +padding-left(14px)
        +padding-right(40px)
        box-shadow: 4px 8px 32px rgba(0, 0, 0, 0.06)
        color: #000
        border: none
        +m
            line-height: 1.5
            padding-top: 7px
            padding-bottom: 7px
            +padding-left(30px)
            +padding-right(10px)
            font-size: 14px
            letter-spacing: normal
            border-radius: 100px
    &_material &__check
        +m
            .mat-icon
                display: block
                color: #ccc
    &_orange &__input:checked + &__title,
    &_orange &__input_checked + &__title
        background-color: #F7711E
        border-color: #F7711E
        color: #fff
        +m
            .mat-icon
                color: #fff
    &_blue &__input:checked + &__title,
    &_blue &__input_checked + &__title
        background-color: #2F80ED
        border-color: #2F80ED
        color: #fff
        +m
            .mat-icon
                color: #fff
    &_green &__input:checked + &__title,
    &_green &__input_checked + &__title
        background-color: $green
        border-color: $green
        color: #fff
        +m
            .mat-icon
                color: #fff
    &_bluet &__input:checked + &__title,
    &_bluet &__input_checked + &__title
        background-color: $bluet
        border-color: $bluet
        color: #fff
        +m
            .mat-icon
                color: #fff
    &_purple &__input:checked + &__title,
    &_purple &__input_checked + &__title
        background-color: $purple
        border-color: $purple
        color: #fff
        +m
            .mat-icon
                color: #fff
    &_experiencesmall,
    &_img
        +di-flex
        +jc(space-between)
        +t
            text-align: start
            flex-wrap: wrap
        +m
            text-align: start
            flex-wrap: wrap
    &_tal
        flex-wrap: wrap
        +jc(space-between)
        margin-top: -25px
        +t
            margin-top: 0
        +m
            margin-top: 0
    &_img &__col
        width: 18.3%
        margin-bottom: 0
        padding: 0
        height: 56px
        +t
            width: 100%
            margin-bottom: 15px
            margin-top: 0
        +m
            width: 100%
            margin-bottom: 15px
            margin-top: 0
    &_tal &__col
        width: 24%
        margin-top: 25px
        +t
            width: 100%
            margin-top: 0
        +m
            width: 100%
            margin-top: 0
    &_experiencesmall &__check,
    &_img &__check
        +di-flex
        +align(center)
        height: 100%
        width: 100%
    &_experiencesmall &__box,
    &_img &__box
        +di-flex
        +align(center)
        border-radius: 4px
        box-shadow: 2px 4px 12px rgba(0, 0, 0, .04)
        border: 1px solid #fff
        padding: 0px 12px
        background-color: #FEFEFE
        width: 100%
        height: 100%
    &_experiencesmall &__pic,
    &_img &__pic
        margin-bottom: 0
        max-height: 25px
        max-width: 25px
        +m
            display: block
    &_experiencesmall &__input:checked + &__box,
    &_img &__input:checked + &__box
        border-color: #F7711E
    &_experiencesmall &__input:checked + &__box &__title,
    &_img &__input:checked + &__box &__title
        color: #F7711E
    &_experiencesmall &__title,
    &_img &__title
        height: auto
        border-color: transparent
        background: none
        box-shadow: none
        font-size: 14px
        border-radius: 0
        opacity: 1
        line-height: 1.5
        color: #000
        padding: 0
        +margin-left(10px)
        white-space: normal
        overflow: visible
        text-transform: none
        text-align: start
        cursor: inherit
        +m
            font-size: 16px
            color: #000
            +di-flex
            +align(center)
            width: 100%
            margin: 0
            height: 100%
            +margin-left(15px)
            text-align: start
            padding: 0
    &_experiencesmall &__input:checked + &__title,
    &_img &__input:checked + &__title
        border-color: transparent
        background: transparent
        color: #000
        &:after
            opacity: 1
    &_vacancy
        margin: 0
        margin-bottom: 20px
    &_vacancy &__col
        width: 48%
    &_double
        +di-flex
        +jc(flex-start)
        flex-wrap: wrap
        +margin-left(-10px)
        +margin-right(-10px)
        margin-top: -20px
        +m
            grid-template-columns: 1fr
    &_double &__col
        width: 50%
        margin-bottom: 0
        padding: 0 10px
        margin-top: 20px
        +m
            width: 100%
    &_double &__col_lg
        width: 100%
    &_double &__col_hr
        +di-flex
        +align(center)
    &_triple
        flex-wrap: wrap
        +margin-left(-10px)
        +margin-right(-10px)
        +jc(flex-start)
        +t
            display: grid
            grid-gap: 20px
            grid-template-columns: 1fr 1fr
            +margin-left(0)
            +margin-right(0)
        +m
            +margin-left(0)
            +margin-right(0)
    &_triple &__col
        width: 33%
        margin-bottom: 17px
        padding: 0 10px
        +t
            width: 100%
            margin-bottom: 0
            padding: 0
        +m
            width: 100%
            padding: 0
    &_two
        +margin-left(-18px)
        margin-top: -18px
        flex-wrap: wrap
        +jc(flex-start)
        +m
            +margin-left(0)
    &_two &__col
        width: 323px
        margin-top: 18px
        +margin-left(18px)
        +m
            width: 100%
            +margin-left(0)
    &_two &__box
        box-shadow: none
        background-color: rgba(45, 156, 219, 0.04)
        border-radius: 4px
    &_two &__title
        +margin-left(12px)
        +m
            font-size: 14px
    &_triple &__title
        text-align: start
    &_directions
        margin: 0
    &_directions &__pic
        max-height: 24px
    &_directions &__input:checked + &__box,
    &_blue &__input:checked + &__box
        border-color: #2F80ED
    &_directions &__input:checked + &__box &__title,
    &_blue &__input:checked + &__box &__title
        color: #2F80ED
    &_tal &__input:checked + &__box,
    &_green &__input:checked + &__box
        border-color: $green
    &_tal &__input:checked + &__box &__title,
    &_green &__input:checked + &__box &__title
        color: $green
    &_bluet.choice_experiencesmall &__col,
    &_bluet.choice_two &__col
        width: 48%
        +m
            width: 100%
    &_bluet &__input:checked + &__box
        border-color: $bluet
    &_bluet &__input:checked + &__box &__title
        color: $bluet
    &_purple &__input:checked + &__box
        border-color: $purple
    &_purple &__input:checked + &__box &__title
        color: $purple
    //&_big &__title
    //    border: none
    //    display: block
    //    position: relative
    //    border-radius: 50px
    //    background: #ffffff
    //    vertical-align: top
    //    font-size: 16px
    //    user-select: none
    //    cursor: pointer
    //    +text-overflow
    //    transition: all .2s
    //    height: 42px
    //    line-height: 41px
    //    +padding-left(14px)
    //    +padding-right(40px)
    //    box-shadow: 4px 8px 32px rgba(0, 0, 0, 0.06)
    //    color: #000
    //&_big &__input:checked + &__title
    //    background: $orange
    //    border-color: transparent
    //    color: #fff
    //    opacity: 1
    //&_big &__input:checked + &__title &__icon
    //    transform: rotate(45deg)
    //    .mat-icon_close
    //        stroke: #fff
    &_directions &__title
        +margin-left(12px)
    +m
        &_img
            +margin-left(0)
            +margin-right(0)
        &_experiencesmall &__title,
        &_img &__title
            letter-spacing: 0
        &_nowrap
            flex-wrap: wrap
        &_vacancy &__col
            width: 100%
        &_fifth
            +di-flex
        &_fifth &__col
            width: 48%
            height: auto
            min-height: 56px
            &:last-child
                width: 100%
        &_fifth &__title
            font-size: 14px
            letter-spacing: normal
            margin-bottom: 0


:host-context(.choice_img)
    .choice
        &__title
            padding: 0
            border: none
            color: #000
            text-transform: none
            letter-spacing: normal
            font-size: 14px
        &__pic
            margin-bottom: 0
            max-width: none
            max-height: 25px
