@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-Regular.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-Regular.woff') format('woff')
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-RegularItalic.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-RegularItalic.woff') format('woff')
    font-weight: normal
    font-style: italic
    font-display: swap

// @font-face
//     font-family: 'SF Pro Display'
//     src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-Semibold.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-Semibold.woff') format('woff')
//     font-weight: 600
//     font-style: normal
//     font-display: swap

// @font-face
//     font-family: 'SF Pro Display'
//     src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),url('/assets/fonts/SFProDisplay-SemiboldItalic.woff') format('woff')
//     font-weight: 600
//     font-style: italic
//     font-display: swap

@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-MediumItalic.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-MediumItalic.woff') format('woff')
    font-weight: 600
    font-style: italic
    font-display: swap


@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-Medium.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-Medium.woff') format('woff')
    font-weight: 600
    font-style: normal
    font-display: swap


@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-MediumItalic.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-MediumItalic.woff') format('woff')
    font-weight: 500
    font-style: italic
    font-display: swap


@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-Medium.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-Medium.woff') format('woff')
    font-weight: 500
    font-style: normal
    font-display: swap


// @font-face
//     font-family: 'SF Pro Display'
//     src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-Black.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-Black.woff') format('woff')
//     font-weight: 900
//     font-style: normal
//     font-display: swap

// @font-face
//     font-family: 'SF Pro Display'
//     src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-BlackItalic.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-BlackItalic.woff') format('woff')
//     font-weight: 900
//     font-style: italic
//     font-display: swap


@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-Bold.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-Bold.woff') format('woff')
    font-weight: 800
    font-style: normal
    font-display: swap


@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-BoldItalic.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-BoldItalic.woff') format('woff')
    font-weight: 800
    font-style: italic
    font-display: swap

@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-Bold.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-Bold.woff') format('woff')
    font-weight: 900
    font-style: normal
    font-display: swap


@font-face
    font-family: 'SF Pro Display'
    src: local('SF Pro Display'), url('/assets/fonts/SFProDisplay-BoldItalic.woff2') format('woff2'), url('/assets/fonts/SFProDisplay-BoldItalic.woff') format('woff')
    font-weight: 900
    font-style: italic
    font-display: swap
