@import 'sliders'
@import 'dialogs'
@import 'selects'
@import 'form-field'
@import 'buttons'

html
    -webkit-print-color-adjust: exact
    --mat-dialog-container-max-width: 100%
    --mat-dialog-container-min-width: 300px
    --mat-form-field-error-text-color: var(--mdc-theme-error)
    --mdc-checkbox-selected-checkmark-color: #fff

body
    .mat-checkbox-layout
        white-space: normal
        align-items: center
        .mdc-form-field > label
            line-height: 1.2
    .mat-drawer
        overflow: hidden
    .mat-drawer-inner-container
        overflow: hidden

[hidden]
    display: none !important

input[type="text"]
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
// disable up down arrows
input[type="number"]
    -moz-appearance: textfield
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    margin: 0
button,
a,
span
    outline: none

h6
    font-weight: 400

mat-label
    user-select: none

mat-checkbox.mat-bold
    padding-top: 0
    font-weight: bold
    font-size: 18px

.mat-mdc-checkbox
    .mdc-checkbox__ripple
        border-radius: 0

mat-checkbox.mat-checkbox_big
    .mdc-checkbox
        --mdc-checkbox-ripple-size: 18px
        padding: 0
        width: 20px
        height: 20px
        flex-basis: 20px
        .mdc-checkbox__background
            width: 20px
            height: 20px
            border-width: 1px
            border-color: $orange
            border-radius: 4px
            left: 0
            top: 0
        .mdc-checkbox__checkmark
            width: 15px
            height: 15px
            top: 1px
            left: 1px
        .mdc-checkbox__native-control
            left: 0
            right: 0
            top: 0
            bottom: 0
    .mdc-form-field > label
        word-break: break-word
        padding-left: 10px

mat-checkbox.mat-checkbox_scroll60
    .mdc-label
        max-height: 60px
        overflow-y: auto
        +scrollbar

mat-checkbox.mat-checkbox_black
    .mdc-form-field
        --mdc-form-field-label-text-size: 16px
        --mdc-typography-body2-font-size: 16px
        --mdc-typography-body2-letter-spacing: 0.16px
    .mdc-checkbox
        padding: 0
        .mdc-checkbox__background
            width: 19px
            height: 19px
            border-width: 1px
            border-radius: 4px
            border-color: #000
            left: 0
            top: 0
        .mdc-checkbox__native-control
            left: 0
            right: 0
            top: 0
            bottom: 0
    .mdc-form-field > label
        line-height: 1.32
        padding-left: 10px
    .mdc-checkbox__checkmark
        top: 1px
        right: 1px
        bottom: 1px
        left: 1px
        width: 14px

mat-checkbox.mat-checkbox_black2
    .mdc-checkbox
        padding: 0
        .mdc-checkbox__native-control
            left: 0
            right: 0
            top: 0
            bottom: 0
        .mdc-checkbox__background
            width: 19px
            height: 19px
            border-width: 1px
            border-radius: 2px
            border-color: #000
            left: 0
            top: 0
    .mdc-form-field > label
        line-height: 1.32
        padding-left: 10px
    .mdc-checkbox__checkmark
        top: 1px
        +right(1px)
        bottom: 1px
        +left(1px)
        width: 17px

.mat-tooltip
    font-size: 14px
    line-height: 1.1
    &_metro
        font-size: 18px
        line-height: 1.1

.tutor
    mat-checkbox.mat-checkbox_big
        .mdc-checkbox__background
            border-color: $blue
.petcare
    mat-checkbox.mat-checkbox_big
        .mdc-checkbox__background
            border-color: $green
.driver
    mat-checkbox.mat-checkbox_big
        .mdc-checkbox__background
            border-color: $cryola
.cook
    mat-checkbox.mat-checkbox_big
        .mdc-checkbox__background
            border-color: $bondi
.housekeeper
    mat-checkbox.mat-checkbox_big
        .mdc-checkbox__background
            //border-color: $bluet
            border-color: #c7e4fc
.nurse
    mat-checkbox.mat-checkbox_big
        .mdc-checkbox__background
            border-color: $purple

.checkbox-group__checkbox
    &.mat-checkbox-disabled .mdc-form-field > label
        color: #000
    &.mat-checkbox-disabled .mdc-checkbox
        color: $orange
        .housekeeper &
            color: $bluet
        .tutor &
            color: $blue
        .petcare &
            color: $green
        .nurse &
            color: $purple
        .driver &
            color: $cryola
        .cook &
            color: $bondi
    &.mat-checkbox-disabled:not(.mat-checkbox-checked) .mdc-checkbox__background
        border-color: currentColor
    &.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
    &.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background
        background-color: currentColor

// calendar
.mat-datepicker-calendar-pane.mat-pane-open
    z-index: 999
.mat-datepicker-content
    box-shadow: none
    .mat-calendar
        position: absolute
        z-index: 1
        border: 1px solid rgb(155, 155, 155)
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
.mat-default-theme .mat-datepicker-input-mask-opaque,
.mat-datepicker-input-mask-opaque
    box-shadow: 0 0 0 9999px rgb(255, 255, 255), 0 0 0 1px rgb(255, 255, 255) inset
mat-datepicker
    padding: 0
    margin: 0
mat-datepicker-toggle
    .mat-icon-button
        height: 100%
        width: 100%
        +di-flex
        +align(center)
        +jc(center)
        .mat-mdc-button-wrapper
            +di-flex
            +align(center)
            margin: 0 auto
.registration
    mat-datepicker-toggle
        .mat-icon-button
            width: 100%
            height: 100%
            opacity: 0
.date-field__material
    .mat-mdc-button-wrapper
        color: $orange
.date-field_blue
    .mat-mdc-button-wrapper
        color: #2F80ED
.date-field_green
    .mat-mdc-button-wrapper
        color: $green
.date-field_bluet
    .mat-mdc-button-wrapper
        color: $bluet
.date-field_purple
    .mat-mdc-button-wrapper
        color: $purple

.mat-calendar-controls .mat-calendar-period-button
    margin: 0

.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical)
    background-color: $orange
    color: #fff

.cdk-keyboard-focused .mat-calendar-body-active,
.cdk-program-focused .mat-calendar-body-active
    & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected)
        background-color: $orange
        color: #fff
// calendar end

// paginator
.mat-paginator-outer-container
    .mat-paginator-container
        min-height: 74px
.mat-paginator-range-actions
    font-size: 18px
    font-weight: 500
    color: #000
.mat-icon-button
    &.mat-paginator-navigation-next,
    &.mat-paginator-navigation-previous
        color: #044c95
.mat-paginator-navigation-previous
    order: 1
.mat-paginator-navigation-next
    order: 3
.mat-paginator-range-label
    order: 2
    margin: 0 20px
.loading-overlay
    position: fixed
    +left(0)
    top: 0
    +right(0)
    bottom: 0
    z-index: 1119
    background-color: #f6f6f6
    +di-flex
    +jc(center)
    +align(center)
    width: 100%
    mat-progress-spinner
        top: auto
        +left(auto)
        margin: 0 auto
    &.show_header
        top: $header_height
        +d
            top: $header_height
            z-index: 1119
        +t
            top: $header_height
            z-index: 98
// paginator end

.loader
    +d
        height: 500px
    +m
        height: 200px
    &__wrap
        position: absolute
        background: #f6f6f6
        +left(0)
        bottom: 0
        top: $header_height
        +right(0)
        transition: all 0.4s
        z-index: 1099
    &__count
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        text-align: center
        background: rgba(255, 255, 255, 0.5)
        +di-flex
        +align(center)
        +jc(center)
        mat-progress-spinner,
        mat-spinner
            position: relative
            width: 20px
            height: 20px
            margin: 0 auto
            +left(0px)
            top: 0

mat-progress-spinner,
mat-spinner
    margin: 20px auto
    width: 100px
    height: 100px
    position: fixed
    top: 50%
    +left(50%)
    margin-top: -100px
    +margin-left(-50px)
    color: $blue
    z-index: 1300
    &.mat-progress-spinner_relative
        position: relative
        margin: 20px auto
        +left(auto)
        top: auto
    path
        color: $blue
        fill: $blue
        stroke: $blue

[ng-click]
    outline: none

select,
button,
textarea,
input
    font: normal normal 14px $font
    vertical-align: baseline

button,
input,
textarea
    background: none
    border: none
    outline: none
    font-weight: normal
    color: #000

a
    color: $blue
    cursor: pointer
    text-decoration: none

strong
    font-weight: 800
    [dir="rtl"] &
        font-weight: 700

input,
textarea
    &:focus
        &::placeholder
            color: transparent

// Fix Android 4.0 button bugs
input[type="reset"],
input[type="submit"],
html input[type="button"],
button
    cursor: pointer
    -webkit-appearance: button
    &[disabled]
        cursor: default

textarea
    vertical-align: top
    overflow: auto
    resize: none

input
    &[type="search"]
        -webkit-appearance: textfield
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button
            -webkit-appearance: none
    &:-webkit-autofill
        text-shadow: none

.page
    +m
        padding-bottom: 120px

.pac-container
    +margin-left(0)
    z-index: 9999999999 !important
    border-radius: 0
    border: none

.pac-logo
    &:after
        display: none

.pac-icon
    display: none

.pac-item
    cursor: pointer
    padding: 0 10px

.pac-item-query
    font-size: 11px

.wrapper
    position: relative
    min-height: 100vh
    padding-top: 60px
    background: #ffffff
    overflow: hidden
    @media print
        .wrapper
            padding-top: 0

.slider
    position: relative
    margin-bottom: 21px
    color: #878787
    user-select: none
    &__scale
        position: absolute
        padding-top: 7px
        font-size: 14px
        color: #000
    &_form
        color: #000
    &_form &__scale
        font-size: 16px
    +m
        &__scale
            padding-top: 4px
            &-item
                color: #000

.deactivatePanel
    mat-dialog-container
        .mat-mdc-dialog-content
            padding: 30px 40px
            padding-bottom: 0
    +m
        mat-dialog-container
            .mat-mdc-dialog-content
                padding: 0 24px
                width: auto

.mat-calendar
    background: #fff

.mat-calendar-body-cell
    &.mat-calendar-body-active
        .mat-calendar-body-cell-content
            background-color: #fff
            border-color: currentColor
            color: $orange
            .housekeeper &
                color: $bluet
            .nurse &
                color: $purple
            .petcare &
                color: $green
            .tutor &
                color: $blue
            .cook &
                color: $bondi
            .driver &
                color: $cryola
        &:hover
            .mat-calendar-body-cell-content
                background-color: $orange
                border-color: $orange
                color: #fff
                .housekeeper &
                    background-color: $bluet
                    border-color: $bluet
                .nurse &
                    background-color: $purple
                    border-color: $purple
                .petcare &
                    background-color: $green
                    border-color: $green
                .tutor &
                    background-color: $blue
                    border-color: $blue
                .cook &
                    background-color: $bondi
                    border-color: $bondi
                .driver &
                    background-color: $cryola
                    border-color: $cryola
    &:not(.mat-calendar-body-disabled).mat-calendar-body-active:hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected)
        background-color: $orange
        border-color: $orange
        color: #fff
        .housekeeper &
            background-color: $bluet
            border-color: $bluet
        .nurse &
            background-color: $purple
            border-color: $purple
        .petcare &
            background-color: $green
            border-color: $green
        .tutor &
            background-color: $blue
            border-color: $blue
        .cook &
            background-color: $bondi
            border-color: $bondi
        .driver &
            background-color: $cryola
            border-color: $cryola

.mdc-checkbox-no-side-margin
    +margin-right(8px !important)

.mat-select-trigger
    width: 100%

.mat-menu-panel
    background-color: #fafafa

.mdc-linear-progress
    &.mat-progress-bar_styled
        --mdc-linear-progress-track-height: 8px
        --mdc-linear-progress-active-indicator-color: #21B9E9
        --mdc-linear-progress-active-indicator-height: 8px
        display: block
        overflow: hidden
        transform: translateZ(0)
        transition: opacity 250ms linear
        width: 100%
        z-index: 10
        position: fixed
        +left(0)
        +right(0)
        top: #{$header_height}
        +m
            top: 0
        .mdc-linear-progress__buffer-dots
            display: none
        .mdc-linear-progress__buffer-bar
            background-color: #E9F9FF
    &.mat-progress-bar_create
        --mdc-linear-progress-track-height: 6px
        --mdc-linear-progress-active-indicator-color: #1273d6
        border-radius: 8px
        .mdc-linear-progress__buffer-bar
            background-color: #e7f1fb
    &.mat-progress-bar_blue
        --mdc-linear-progress-track-height: 6px
        --mdc-linear-progress-active-indicator-color: #6A93AD
        border-radius: 4px
        .mdc-linear-progress__buffer-bar
            background-color: #E1E9EF

.mat-chip-list-wrapper
    width: 100%

.mat-chip-list_tags
    width: 100%
    .mdc-evolution-chip__text-label
        display: flex
        align-items: center
    +m
        display: block
        +margin-left(-22px)
        +margin-right(-22px)
        overflow-x: auto
        width: auto
        +padding-left(22px)
        +padding-right(22px)
        .mat-chip-list-wrapper
            flex-wrap: nowrap
            margin: 0
            padding: 0

.autocomplete-select_shadow
    .mat-form-field__placeholder
        align-self: center
    .mat-chip-list-wrapper
        //display: block
        padding: 8px 12px
        margin: 0
        font-size: 14px
        align-items: stretch
input.mat-chip-input
    width: auto

// calendar start
.mat-calendar_styled
    display: block
    width: 100%
    .mat-calendar-table
        width: 100%
        border-spacing: 0
        border-collapse: collapse
    .mat-calendar-controls
        +jc(space-between)
        +align(center)
        margin-top: 0
    .mat-calendar-period-button
        order: 2
    .mat-calendar-previous-button
        order: 1
    .mat-calendar-next-button
        order: 3
    .mat-calendar-table-header
        .mat-calendar-table-header-divider
            display: none
        th
            background-color: #f5f5f5
            font-size: 16px
            padding: 9px 3px
            color: #000
            &:first-child
                border-radius: 20px 0 0 20px
                [dir="rtl"] &
                    border-radius: 0 20px 20px 0
            &:last-child
                border-radius: 0 20px 20px 0
                [dir="rtl"] &
                    border-radius: 20px 0 0 20px
    .mat-calendar-table-header-divider
        &:after
            display: none
    .mat-calendar-body-label
        font-size: 0
        opacity: 0
    .mat-calendar-body-cell-content
        font-size: 16px
        color: #000
        // display: block
        // line-height: 40px
    .mat-calendar-controls
        .mdc-icon-button.mat-mdc-button-base
            width: 32px
            height: 32px
            background-color: #f5f5f5
            border-radius: 50%
            overflow: hidden
        .mat-calendar-previous-button::after,
        .mat-calendar-next-button::after
            margin: 11px
    .mat-calendar-body-selected
        background-color: $orange_bg

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical)
    background-color: $orange
    color: #fff
    .nanny &
        background-color: $orange
        border-color: $orange
        color: #fff
    .housekeeper &
        background-color: $bluet
        border-color: $bluet
        color: #fff
    .nurse &
        background-color: $purple
        border-color: $purple
        color: #fff
    .petcare &
        background-color: $green
        border-color: $green
        color: #fff
    .tutor &
        background-color: $blue
        border-color: $blue
        color: #fff
    .cook &
        background-color: $bondi
        border-color: $bondi
        color: #fff
    .driver &
        background-color: $cryola
        border-color: $cryola
        color: #fff

// calendar end

.mat-calendar-spacer
    display: none

.form_create
    mat-form-field.mat-form-field_styled .mat-mdc-form-field-flex
        border-color: #999

// tabs
.mat-tab-group_styled
    .mat-tab-nav-bar, .mat-mdc-tab-header
        border-block-end: none
    .mat-mdc-tab-header
        border: none
    .mat-mdc-tab
        height: 57px
        font-size: 16px
        text-transform: uppercase
        line-height: 1.75
        letter-spacing: normal
        font-weight: 600
        opacity: 1
        background-color: #fafcfe
        flex-grow: 1
        flex-shrink: 1
    .mdc-tab--active
        opacity: 1
        background-color: #f6fafe
    .mdc-tab__text-label
        color: #408edd
    .mdc-tab--active .mdc-tab__text-label
        color: #1c79d7
    .mat-mdc-tab-body-wrapper
        overflow: hidden
        padding: 0
    .mat-mdc-tab-header-pagination
        background-color: #fafcfe
    &.mat-accent
        .mat-mdc-tab:not(.mat-tab-disabled):focus,
        .mat-tab-link:not(.mat-tab-disabled):focus
            background: none
    +t
        .mat-mdc-tab
            font-size: 16px
            font-weight: 500
            padding: 0 15px

.mat-tab-group_works
    --mdc-tab-indicator-active-indicator-color: #6A93AD
    --mat-tab-header-inactive-label-text-color: #6A93AD
    .mat-tab-nav-bar, .mat-mdc-tab-header
        border-block-end: none
    .mat-mdc-tab-header
        border: none
    .mat-mdc-tab
        font-size: 14px
        +padding-left(24px)
        +padding-right(24px)
        text-transform: uppercase
        line-height: 1.75
        letter-spacing: normal
        font-weight: 600
        opacity: 1
        background-color: #FCFDFD
        flex-grow: 1
        flex-shrink: 1
    .mdc-tab__text-label
        color: #7C9CAE
    .mdc-tab--active
        opacity: 1
        background-color: #F9FBFC
    .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label
        color: #6A93AD
    .mdc-tab-indicator
        top: auto
        bottom: 0
        height: 2px
    // .mdc-tab--active .mdc-tab-indicator
    //     background-color: #6A93AD
    .mat-mdc-tab-body-wrapper
        overflow: hidden
        padding: 0
    .mat-mdc-tab-header-pagination
        background-color: #FCFDFD
    &.mat-accent
        .mat-mdc-tab:not(.mat-tab-disabled):focus,
        .mat-tab-link:not(.mat-tab-disabled):focus
            background: none
    +dd
        .mat-mdc-tab
            height: 57px
            font-size: 16px
            +padding-left(24px)
            +padding-right(24px)
    +t
        .mat-mdc-tab
            font-size: 16px
            font-weight: 500
            padding: 0 15px

.mat-tab-group_settings
    .mat-tab-body
        background-color: #fff
    +m
        .mat-tab-body
            background-color: transparent
        .mat-mdc-tab-header
            position: absolute
            +left(0)
            +right(0)
            top: -57px

.mat-tab-group_home
    &[mat-align-tabs=end] > .mat-mdc-tab-header .mat-mdc-tab-labels
        +m
            justify-content: space-between
    +dd
        margin-top: -48px
    .mat-mdc-tab-header
        border: none
        position: relative
        z-index: 2
    .mdc-tab-indicator
        display: none
    .mat-mdc-tab
        font-size: 16px
        font-weight: normal
        letter-spacing: normal
        background-color: #6A93AD
        opacity: 0.4
        border-radius: 4px
        transition: opacity .4s
        flex: 0 0 48%
        +dd
            flex-basis: auto
            +margin-left(16px)
        .mat-tab-group_home
            +margin-left(16px)
            +margin-right(0)
    .mdc-tab__text-label,
    .mat-mdc-tab .mdc-tab__text-label,
    .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label
        color: #fff
    .mdc-tab--active,
    .mat-mdc-tab:hover .mdc-tab__text-label,
    .mat-mdc-tab:hover
        color: #fff
        opacity: 1
    .mat-mdc-tab-labels
        justify-content: space-between
        margin-bottom: 10px
        +dd
            justify-content: flex-end
    .mat-tab-body-wrapper
        +dd
            margin-top: -17px

.mat-tab-group_vertical.mat-tab-group
    flex-direction: row
    .mat-mdc-tab-header
        flex: 0 0 30%
    .mat-tab-body-wrapper
        flex: 1 1 70%
    .mat-mdc-tab-labels
        flex-direction: column
    .mdc-tab-indicator
        display: none
    .mdc-tab--active
        background-color: #d1e5fa
    .mat-mdc-tab-content
        white-space: normal
        line-height: 1.2
    .mat-mdc-tab
        padding: 10px 20px
        text-align: start
        text-transform: none
        height: auto
        min-height: 57px
        +jc(flex-start)
    +m
        flex-direction: column

.mat-tab-group_my-vacancies
    .mat-mdc-tab-header
        margin-top: -32px
        border: none
    .mat-mdc-tab
        width: 50%

.mat-tab-group_pill
    margin: 0 -10px
    &.mat-mdc-tab-group
        max-width: none
    .mat-mdc-tab-body.mat-mdc-tab-body-active
        overflow: visible
    .mat-mdc-tab-header,
    .mat-mdc-tab-body-wrapper
        padding: 0 10px
    .mat-mdc-tab-body-content
        overflow: visible
    .mat-mdc-tab-header
        margin-bottom: 30px
    .mat-mdc-tab
        height: 46px
        min-width: 80px
        font-weight: 500
        font-size: 16px
        line-height: 1
        padding-bottom: 3px
        letter-spacing: normal
        border-radius: 50px
        opacity: 1
        +padding-left(12px)
        +padding-right(12px)
    .mdc-tab__text-label
        color: #6A93AD
    .mdc-tab--active
        background-color: #6A93AD
    .mdc-tab--active .mdc-tab__text-label,
    .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label
        color: #fff
    .mdc-tab-indicator
        display: none
    .mat-tab-nav-bar, .mat-mdc-tab-header
        border: none
    +dd
        margin: 0 -28px
        margin-top: 32px
        .mat-mdc-tab-labels
            justify-content: space-between
            padding: 0 20px
        .mat-mdc-tab
            min-width: 118px
        .mat-mdc-tab-header,
        .mat-mdc-tab-body-wrapper
            padding: 0 28px
// chips
mat-chip
    &.mat-standard-chip.mat-chip-with-trailing-icon
        padding: 9px 14px
        font-size: 14px
        height: auto
        border-radius: 22px
        background-color: $orange
        color: #fff
    &:not(.mat-basic-chip)
        .mat-chip-trailing-icon.mat-icon,
        .mat-chip-remove.mat-icon
            stroke: #fff
            width: 14px
            height: 14px
            +margin-left(20px)
            transform: rotate(45deg)
            opacity: 1
mat-chip.mat-chip_styled
    &:not(.mat-basic-chip)
        padding: 4px 12px
        margin: 4px
        // & + .mat-chip:not(.mat-basic-chip)
        //     margin: 4px
        .mat-chip-remove.mat-icon
            opacity: 1
            width: 12px
            height: 12px
            display: block
            transform: rotate(45deg)
            stroke: #fff

.mat-drawer-backdrop
    transition: all .4s
    &.mat-drawer-shown
        background-color: rgba(0, 64, 139, 0.4)

.capitalize
    text-transform: capitalize

.mat-expansion-panel.mat-expansion-panel_styled
    margin-bottom: 0
    margin-top: 0
    .mat-content
        +align(center)
    &:not([class*=mat-elevation-z])
        box-shadow: none
        border-radius: 0
        overflow: hidden
    &:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true'])
        background: none
    &.mat-expansion-panel:not(.mat-expanded), .mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing)
        border-radius: 0
    .mat-expansion-panel-header
        font-size: 20px
        letter-spacing: normal
        padding: 0
        font-weight: 500
        line-height: 1.44
        min-height: 92px
        border-block-end: 1px solid #6A93AD
        .mat-icon
            fill: none
            transform: rotate(90deg)
            stroke: #000
            width: 12px
            height: 12px
            transition: .2s transform
        &.mat-expanded
            .mat-expansion-panel-header-description
                .mat-icon
                    transform: rotate(-90deg)
    .mat-expansion-panel-header-description
        +jc(flex-end)
        +margin-right(0)
    .mat-expansion-panel-header-description
        background-color: #F7FBFE
        +di-flex
        width: 32px
        height: 32px
        flex: 0 0 32px
        +align(center)
        +jc(center)
        border-radius: 50%
        .mat-icon_close
            transition: .4s all
            stroke: #fff
            width: 14px
            height: 14px
            stroke-width: 2
    // .mat-expansion-indicator
    //     width: 26px
    //     height: 26px
    //     background: url('/assets/img/home/accordion.png') 50% 50% no-repeat
    //     background-size: cover
    //     &:after
    //         display: none
    .mat-expansion-panel-content
        .mat-expansion-panel-body
            padding: 22px 0
            position: relative
        p
            margin: 0
            line-height: 2.14
    .mat-expansion-panel-header-title
        display: block
    .mat-expansion-panel-header-description
        flex-grow: 0
        flex-shrink: 0
    +m
        margin-bottom: 26px
        .mat-expansion-panel-header
            .mat-expansion-panel-header-title
                font-size: 18px
                line-height: 26px

.mat-expansion-panel.mat-expansion-panel_faq
    background: none
    box-shadow: none
    color: #000
    padding: 0
    border-block-end: 1px solid #C2D3DE
    border-radius: 0
    &:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true])
        background: transparent
    &:not([class*=mat-elevation-z])
        box-shadow: none
        border-radius: 0
        overflow: hidden
    .mat-expansion-indicator
        display: none
    .mat-expansion-panel-header
        padding: 30px 0
        font-size: 20px
        letter-spacing: normal
    .mat-expansion-panel-body
        +padding-left(0)
        +padding-right(0)
    &.mat-expansion-panel-spacing
        margin: 0
    .mat-expansion-panel-header-description
        +margin-right(0)
        +margin-left(auto)
        flex: 0 0 32px
        height: 32px
        width: 32px
        background-color: #F5FAFE
        border-radius: 50%
        display: flex
        justify-content: center
        align-items: center
        .mat-icon
            fill: none
            stroke: #000000
            transform: rotate(90deg)
            width: 12px
            height: 13px
    .mat-expansion-panel-header-description
        transition: transform 0.4s
    .mat-expanded
        .mat-expansion-panel-header-description
            transform: rotate(-180deg)

.lowercase
    text-transform: lowercase
.capitalize
    text-transform: capitalize

.matfield-fake
    border: 1px solid #999
    background-color: #fff
    border-radius: 4px

.hr
    height: 0
    border: none
    border-block-start: 1px dashed $orange
    margin: 50px 0
    display: block
    &-nanny
        border-block-start: 1px dashed $orange
    &-petcare
        border-block-start: 1px dashed $green
    &-housekeeper
        border-block-start: 1px dashed $bluet
    &-nurse
        border-block-start: 1px dashed $purple
    &-tutor
        border-block-start: 1px dashed $blue

// bad code

.unsubscribe__confirm,
.unsubscribe__change,
.unsubscribe__decline
    &.mat-mdc-button
        &:hover
            .mat-mdc-button-focus-overlay
                opacity: 0

.spacer
    border-block-start: 1px dashed transparent
    margin: 40px 0
    .nanny &
        border-color: $orange
    .housekeeper &
        border-color: $bluet
    .tutor &
        border-color: $blue
    .nurse &
        border-color: $purple
    .petcare &
        border-color: $green
    +m
        margin: 24px 0

// icons
.icon__circle
    .nanny &
        fill: $orange
    .housekeeper &
        fill: $bluet
    .tutor &
        fill: $blue
    .nurse &
        fill: $purple
    .petcare &
        fill: $green

.toggle
    &__unchecked.mat-mdc-slide-toggle .mdc-form-field > label
        color: #CCCCCC

.mat-mdc-slide-toggle
    --mdc-switch-disabled-track-opacity: 1
    .mdc-form-field > label
        font-size: 18px
        color: #ccc
        position: relative
        top: -1px
    &.mat-mdc-slide-toggle-checked
        .mdc-form-field > label
            color: #333
    .mdc-switch:disabled
        .mdc-switch__shadow
            display: none

.mdc-switch
    --mdc-switch-unselected-handle-color: #fafafa
    --mdc-switch-unselected-hover-handle-color: #fafafa
    --mdc-switch-unselected-pressed-handle-color: #fafafa
    --mdc-switch-unselected-focus-handle-color: #fafafa
    --mdc-switch-handle-surface-color: #fcb6ac
    --mdc-switch-unselected-track-color: #9e9e9e
    --mdc-switch-unselected-hover-track-color: #9e9e9e
    --mdc-switch-disabled-selected-track-color: rgba(255,87,34,0.54)
    --mdc-switch-disabled-handle-elevation: none
    --mdc-switch-disabled-selected-handle-color: #fcb6ac
    --mdc-switch-selected-track-color: #fea38b
    &__icons
        display: none

.settings-item.mat-mdc-slide-toggle
    height: auto
    margin: 40px 0
    .mdc-form-field
        width: 100%
        > label
            flex: 1 1 auto
    .mat-slide-toggle-content
        flex: 1 1 calc(100% - 42px)
        font-size: 18px
        white-space: normal
        line-height: 1.32
        +padding-right(10px)
    &.mat-checked .mat-slide-toggle-thumb
        box-shadow: none
        background-color: #F73D16
    +dd
        margin-top: 20px
        margin-bottom: 20px

.mat-mdc-radio-button
    .mdc-radio
        --mdc-radio-state-layer-size: 18px

.radio__input
    // .mat-radio-container
    //     width: 18px
    //     height: 18px
    // .mat-radio-outer-circle
    //     width: 18px
    //     height: 18px
    //     border-width: 1px
    // .mat-radio-inner-circle
    //     width: 18px
    //     height: 18px
    .mdc-radio
        width: 18px
        height: 18px
        &__background
            width: 18px
            height: 18px
        &__inner-circle
            border-width: 9px
        &__outer-circle
            border-color: #000
            border-width: 1px

.dialog_bottom
    +m
        mat-dialog-container.mat-mdc-dialog-container
            background: none

.grecaptcha-badge
    display: none

.paypal-buttons
    width: 200px !important
    height: 42px !important

.cdk-virtual-scroll-orientation-vertical
    .cdk-virtual-scroll-content-wrapper
        width: 100%

.favorite-page__items
    .cdk-virtual-scroll-orientation-horizontal
        +m
            .cdk-virtual-scroll-content-wrapper
                display: flex
                flex-direction: row
                +padding-left(16px)
                +padding-right(16px)
    .cdk-virtual-scroll-orientation-vertical
        +dd
            .cdk-virtual-scroll-content-wrapper
                min-height: 100%
            &::-webkit-scrollbar
                width: 12px
                height: 12px
            &::-webkit-scrollbar-thumb
                background: #CCDCF3
                background-image: url('/assets/img/common/slider-thumb.svg')
                background-position: 50% 50%
                background-repeat: no-repeat
                min-height: 56px
                border-radius: 30px
                cursor: pointer
                visibility: hidden
            &:hover::-webkit-scrollbar-thumb
                visibility: visible
            &::-webkit-scrollbar-thumb:hover
                background-color: #CCDCF3
            &::-webkit-scrollbar-track
                background: #F5F8FD
                border-radius: 30px
                box-shadow: inset 0px 0px 0px 0px #F0F0F0

.vacancy__actions
    .ya-share2__container_shape_round.ya-share2__container_size_l
        .ya-share2__badge
            .ya-share2__icon:not(.ya-share2__icon_messenger-contact):not(.ya-share2__icon_more):not(.ya-share2__icon_copy)
                width: 30px
                height: 30px
                background-size: 24px
    .ya-share2__container_size_l .ya-share2__badge .ya-share2__icon
        width: 30px
        height: 30px
        background-size: 24px

.favorite-tabs
    grid-gap: 16px
    &.mat-button-toggle-group-appearance-standard
        box-shadow: none
        border: none
        border-radius: 0
    &__button
        &.mat-button-toggle-appearance-standard
            .mat-button-toggle-button
                background-color: #F6FAFC
                color: #6A93AD
                font-size: 16px
                font-weight: 500
                line-height: 1.75
                letter-spacing: normal
                border-radius: 4px
            &.mat-button-toggle-checked
                .mat-button-toggle-button
                    background-color: #6A93AD
                    color: #fff
                .mat-mdc-button-toggle-focus-overlay
                    border: none
            .mat-button-toggle-label-content
                line-height: 46px
                padding: 0 30px
            &.mat-button-toggle + .mat-button-toggle
                border: none

.leaflet-control-container
    .leaflet-left
        left: auto
        +left(0)
    .leaflet-control
        margin-left: 0
        +margin-left(10px)

.mat-link
    text-decoration: underline
    color: #1273D6
    letter-spacing: 0.2px
    padding: 0

.mat-primary
    // .mat-pseudo-checkbox-checked
    //     background-color: $orange
    .mat-option.mat-selected:not(.mat-option-disabled)
        color: $orange
.mat-form-field.mat-primary .mat-select-arrow
    color: $orange

.mat-mdc-icon-button
    &.mat-mdc-button-base
        padding: 0

.direction-tabs
    &.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle
        border: none
    &__button
        flex: 0 0 50%
        .mat-button-toggle-button
            border: none
            background-color: #edf5fb
            border-bottom: 2px solid #edf5fb
            color: #1C1B1F
            font-size: 16px
            font-weight: 500
            line-height: 1.75
            letter-spacing: normal
        &.mat-button-toggle-checked
            .mat-button-toggle-button
                border-bottom: 2px solid #6750A4

.markdown
    font-size: 18px
    line-height: 1.48
    padding-top: 20px
    word-break: break-word
    h1,
    h2,
    h3,
    h4,
    h5,
    h6
        line-height: 1.2
        &:first-child
            margin-top: 0
    img
        display: block
        margin-left: auto
        margin-right: auto
        max-width: 100%
    ol
        counter-reset: item
        +padding-left(0)
        > li
            display: block
            +padding-left(60px)
            padding-top: 5px
            padding-bottom: 5px
            min-height: 40px
            position: relative
            margin-bottom: 10px
            &::before
                position: absolute
                +left_c(0)
                top: 0
                content: counter(item)
                font-weight: 700
                counter-increment: item
                display: flex
                justify-content: center
                align-items: center
                width: 40px
                height: 40px
                background: #FFFFFF
                box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.12)
                text-align: center
                font-size: 20px
                line-height: 1.1
                letter-spacing: -0.02em
                color: #000
                border-radius: 50%
