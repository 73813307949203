// buttons
.mat-mdc-raised-button,
.mat-mdc-raised-button.mat-accent
    &:not(:disabled)
        color: #fff

.mdc-button.mat-mdc-outlined-button
    padding: 8px 8px

.mat-mdc-outlined-button.mat-mdc-button-base
    --mdc-outlined-button-outline-color: var(--color_nanny)
    height: auto

.mat-mdc-button-base
    &.mat-mdc-raised-button,
    &.mat-mdc-button
        height: auto
        text-transform: uppercase
        font-size: 14px
        font-weight: 800
        padding: 13px 20px
        min-width: 200px
        text-align: center
        line-height: 1.2
        border-radius: 4px
        letter-spacing: normal
        white-space: normal
        +jc(center)
        border: none
        box-shadow: var(--shadow_light)
        .nanny &,
        .housekeeper &,
        .nurse &,
        .petcare &,
        .cook &,
        .tutor &,
        .driver &
            height: auto
        [dir="rtl"] &
            font-weight: 700
            font-size: 16px
        .mat-mdc-button-wrapper
            margin: 0 auto
            +di-flex
            +align(center)
            +jc(center)
    &.mat-mdc-button_border
        border-width: 1px
        border-style: solid
    &.mat-mdc-raised-button,
    &.mat-mdc-raised-button.mat-mdc-button-base
        &:not([class*=mat-elevation-z])
            box-shadow: none
        &.mat-accent,
        &.mat-primary
            box-shadow: none
            &:not([class*='mat-elevation-z'])
                box-shadow: 2px 6px 32px 0 rgba(0, 0, 0, 0.1)
            &:hover
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13), 0 0 12px 0 rgba(0, 0, 0, 0.3)
            &:not([disabled]):active:not([class*=mat-elevation-z])
                box-shadow: none
    &.mat-icon-button
        .mat-mdc-button-wrapper
            display: block
            margin: 0 auto
        &[mat-dialog-close]
            min-width: 0
            .mat-mdc-button-focus-overlay
                display: none
    &.mat-mdc-button_rounded
        border-radius: 200px
    +m
        &.mat-mdc-raised-button,
        &.mat-mdc-button
            min-width: 0

.mat-mini-fab.mat-mdc-button-base
    font-weight: 800
    [dir="rtl"] &
        font-weight: 700

.mat-mini-fab.mat-mini-fab_small
    box-shadow: none
    background: none
    .nanny &,
    .housekeeper &,
    .tutor &,
    .petcare &,
    .nurse &,
    .driver &,
    .cook &
        background: none
        box-shadow: none
    .mat-mdc-button-wrapper
        padding: 0
        +di-flex
        +align(center)
        +jc(center)

.mat-mini-fab.mat-mini-fab_48
    box-shadow: none
    width: 48px
    height: 48px
    &.mat-fab.mat-accent
        background-color: $orange_bg
    .mat-mdc-button-wrapper
        padding: 0
        +di-flex
        +align(center)
        +jc(center)
// buttons end

.mat-mdc-button-base
    &.mat-mdc-fab,
    &.mat-mdc-mini-fab
        box-shadow: none

html
    .mat-mdc-mini-fab.mat-accent
        --mat-fab-small-foreground-color: #fff

.mat-mdc-button:has(.material-icons,mat-icon,[matButtonIcon])
    padding: var(--mat-text-button-with-icon-horizontal-padding, 8px)
