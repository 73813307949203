mat-icon.mat-icon
    display: block
    fill: currentColor
    width: 1em
    height: 1em
    svg
        display: block
    &_arrow-right
        width: 1.3em
        height: 1em
        [dir=rtl] &
            transform: rotate(180deg)
    &_case
        width: 1.1em
        height: 1em
    &_chat
        width: 29px
        height: 29px
    &_chevron
        [dir=rtl] &
            transform: rotate(180deg)
    &_chevron-left
        width: 0.6em
        height: 1em
        [dir=rtl] &
            transform: rotate(180deg)
    &_chevron-right
        width: 6px
        height: 10px
        [dir=rtl] &
            transform: rotate(180deg)
    &_chevron-down
        width: 12px
        height: 7px
    &_chevron-up
        width: 12px
        height: 7px
        transform: rotate(180deg)
    &_doc-recomm
        width: 0.8em
        height: 1em
    &_doc
        width: 0.8em
        height: 1em
    &_dots
        width: 15px
        height: 15px
    &_eye
        width: 1.6em
        height: 1em
    &_eye2
        width: 24px
        height: 24px
    &_fb
        width: 0.6em
        height: 1em
    &_heart-fill
        width: 1.1em
        height: 1em
    &_heart
        width: 1.1em
        height: 1em
    &_learning
        width: 1.3em
        height: 1em
    &_menu
        width: 1.5em
        height: 1em
    &_ok
        width: 0.7em
        height: 1em
    &_recomm
        width: 0.9em
        height: 1em
    &_rotate
        width: 0.8em
        height: 1em
    &_sofa
        width: 1.7em
        height: 1em
    &_timer
        width: 0.9em
        height: 1em
    &_trash
        width: 12px
        height: 16px
    &_vk
        width: 1.8em
        height: 1em
    &_instagram
        height: 1em
    &_school
        width: 1.4em
        height: 1.4em
    &_profileDashboard
        width: 18px
        height: 18px
    &_profileFile
        width: 16px
        height: 20px
    &_profileUser
        width: 20px
        height: 20px
    &_profileChat
        width: 20px
        height: 20px
    &_profileFavorites
        width: 20px
        height: 19px
    &_profileFavorites2
        width: 20px
        height: 19px
    &_profileGuest
        width: 20px
        height: 14px
    &_profilePayment
        width: 20px
        height: 16px
    &_profileNotify
        width: 20px
        height: 20px
    &_profileSettings
        width: 24px
        height: 24px
    &_profileExit
        width: 21px
        height: 12px
    &_profileRecs
        width: 20px
        height: 16px
    &_office
        width: 18px
        height: 18px
    &_time
        width: 18px
        height: 18px
    &_mail
        width: 19px
        height: 15px
    &_paw
        height: 20px
        width: 20px
    &_registerPassword
        width: 20px
        height: 20px
    &_registerName
        width: 20px
        height: 20px
    &_registerSurname
        width: 20px
        height: 20px
    &_registerPhone
        width: 20px
        height: 20px
    &_registerDate
        width: 20px
        height: 20px
    &_registerEmail
        width: 20px
        height: 20px
    &_premiumPhone
        width: 18px
        height: 18px
    &_premiumChat
        width: 20px
        height: 20px
    &_premiumGuest
        width: 22px
        height: 22px
    &_created
        width: 20px
        height: 20px
    &_arrow-circle
        width: 20px
        height: 20px
    &_successCircle
        width: 60px
        height: 60px
    &_login
        width: 24px
        height: 24px
    &_close
        width: 12px
        height: 12px
        stroke: #818181
        svg
            display: block
    &_cash
        width: 20px
        height: 20px
    &_photo
        width: 23px
        height: 21px
    &_geolocation
        width: 18px
        height: 18px
    &_phone
        width: 18px
        height: 18px
    &_error
        width: 24px
        height: 24px
    &_briefcase
        width: 24px
        height: 24px
    &_calendar
        width: 18px
        height: 18px
    &_work
        width: 20px
        height: 19px
    &_edit
        width: 16px
        height: 16px
    &_format,
    &_filesize,
    &_face,
    &_premiumPhone
        width: 24px
        height: 24px
    &_infocircle
        width: 17px
        height: 17px
    &_chatSend
        width: 24px
        height: 24px
    &_language
        width: 20px
        height: 20px
    &_gloves
        width: 18px
        height: 22px
    &_next
        width: 42px
        height: 42px
    &_sex,
    &_citizens,
    &_lang2,
    &_judge
        width: 32px
        height: 32px
    &_msg
        width: 18px
        height: 18px
        color: #fff
    &_education
        width: 20px
        height: 15px
        svg
            display: block
    &_firstaid
        width: 18px
        svg
            display: block
    &_youtube
        width: 16px
        height: 16px
    &_accessible
        width: 22px
        height: 22px
        flex-basis: 22px
