@use '@angular/material' as mat;
@include mat.core();

mat.$theme-ignore-duplication-warnings: true;
//@import '~@angular/material/theming';
//$custom-typography: mat.define-typography-config($font-family: $font, $button: mat.define-typography-level(12px, 12px, 900), $input: mat.define-typography-level(14px, 1.125, 400));
//@include mat.typography-hierarchy($custom-typography);

$nanny-typography: mat.m2-define-typography-config(
    $font-family: 'SF Pro Display, Tahoma, Geneva, sans-serif',
    $body-1: mat.m2-define-typography-level(16px, 1.6, 400, $letter-spacing: 0.01em),
);

$red-palette: (
    50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #f44336,
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #D0021B,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$cryola-palette: (
    50: #e8eaf6,
    100: #c5cae9,
    200: #9fa8da,
    300: #7987cb,
    400: #5c6cc0,
    500: #3f52b5,
    600: #394aab,
    700: #7986CB,
    800: #273693,
    900: #19247e,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$blue-palette: (
    50: #E6F0FD,
    100: #C1D9FA,
    200: #97C0F6,
    300: #6DA6F2,
    400: #4E93F0,
    500: #2F80ED,
    600: #2A78EB,
    700: #236DE8,
    800: #1D63E5,
    900: #1250E0,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$orange-palette: (
    50: #FEEEE4,
    100: #FDD4BC,
    200: #FBB88F,
    300: #F99C62,
    400: #F88640,
    500: #ff5722,
    600: #F6691A,
    700: #F55E16,
    800: #FF5722,
    900: #F1420A,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: red,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
    )
);

$bluet-palette: (
    50: #E6F0FD,
    100: #C1D9FA,
    200: #97C0F6,
    300: #6DA6F2,
    400: #4E93F0,
    500: #90CAF9,
    600: #2A78EB,
    700: #236DE8,
    800: #1D63E5,
    900: #1250E0,
    contrast: (
        50: #fff,
        100: #fff,
        200: #fff,
        300: #fff,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
    )
);

$purple-palette: (
    50: #F2E6FD,
    100: #DEC0F9,
    200: #C896F6,
    300: #B16CF2,
    400: #A14DEF,
    500: #902DEC,
    600: #8828EA,
    700: #7D22E7,
    800: #731CE4,
    900: #6111DF,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
    )
);

$green-palette: (
    50: #e7f6ec,
    100: #c5e9d1,
    200: #9fdbb5,
    300: #77ce97,
    400: #56c381,
    500: #33b86b,
    600: #2ba860,
    700: #219654,
    800: #198548,
    900: #096534,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$bondi-palette: (
    50: #e0f7fa,
    100: #b2ebf2,
    200: #80deea,
    300: #4dd0e1,
    400: #26c6da,
    500: #00bcd4,
    600: #00acc1,
    700: #0097a7,
    800: #00838f,
    900: #006064,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$app-red: mat.m2-define-palette($red-palette, 500);
$app-blue: mat.m2-define-palette($blue-palette, 500);
$app-orange: mat.m2-define-palette($orange-palette, 500);
$app-bluet: mat.m2-define-palette($bluet-palette, 500);
$app-purple: mat.m2-define-palette($purple-palette, 500);
$app-green: mat.m2-define-palette($green-palette, 700);
$app-bondi: mat.m2-define-palette($bondi-palette, 700);
$app-cryola: mat.m2-define-palette($cryola-palette, 700);

$theme-blue: mat.m2-define-light-theme((
    color: (
        primary: $app-blue,
        accent: $app-blue,
    )
));

$theme-orange: mat.m2-define-light-theme((
    color: (
        accent: $app-orange,
        primary: $app-blue,
        warning: $app-red
    ),
    typography: $nanny-typography,
    density: 0,
));

$theme-bluet: mat.m2-define-light-theme((
    color: (
        primary: $app-blue,
        accent: $app-bluet,
    )
));

$theme-purple: mat.m2-define-light-theme((
    color: (
        primary: $app-blue,
        accent: $app-purple,
    )
));

$theme-green: mat.m2-define-light-theme((
    color: (
        primary: $app-blue,
        accent: $app-green,
    )
));

$theme-bondi: mat.m2-define-light-theme((
    color: (
        primary: $app-blue,
        accent: $app-bondi,
    )
));

$theme-cryola: mat.m2-define-light-theme((
    color: (
        primary: $app-blue,
        accent: $app-cryola,
    )
));

@include mat.all-component-themes($theme-orange);

.nanny {
    // @include mat.all-component-colors($theme-orange);
    @include mat.button-theme($theme-orange);
    @include mat.fab-theme($theme-orange);
    @include mat.checkbox-theme($theme-orange);

    --mdc-checkbox-selected-checkmark-color: #fff;

    // .mat-mdc-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent{
    //     background-color: #FF5722;
    // }

    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
        color: #D0021B;
    }

    .mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker{
        color: #f44336;
    }

    .mat-mdc-button_border{
        border-color: #ff5722;
    }

    // .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    //     border-color: #ff5722;
    // }
}

// blue theme

.tutor {
    // @include mat.all-component-colors($theme-blue);
    @include mat.button-theme($theme-blue);
    @include mat.fab-theme($theme-blue);
    @include mat.checkbox-theme($theme-blue);

    --mdc-checkbox-selected-checkmark-color: #fff;

    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
        color: #D0021B;
    }

    .mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker{
        color: #2F80ED;
    }

    .mat-mdc-button_border{
        border-color: #2F80ED;
    }

    // .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    //     border-color: #2F80ED;
    // }

}

.housekeeper{
    // @include mat.all-component-colors($theme-bluet);
    @include mat.button-theme($theme-bluet);
    @include mat.fab-theme($theme-bluet);
    @include mat.checkbox-theme($theme-bluet);

    --mdc-checkbox-selected-checkmark-color: #fff;

    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
        color: #D0021B;
    }

    .mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker{
        color: #44ABF0;
    }

    .mat-mdc-button_border{
        border-color: #44ABF0;
    }

    // .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    //     border-color: #44ABF0;
    // }

    .mat-mdc-raised-button.mat-accent {
        --mdc-protected-button-label-text-color: #fff;
    }

    .mat-mdc-checkbox.mat-accent {
        --mdc-checkbox-selected-checkmark-color: #fff;
    }

}

.nurse{
    @include mat.all-component-colors($theme-purple);
    @include mat.fab-theme($theme-purple);
    @include mat.checkbox-theme($theme-purple);

    --mdc-checkbox-selected-checkmark-color: #fff;

    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
        color: #D0021B;
    }

    .mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker{
        color: #902dec;
    }

    .mat-mdc-button_border{
        border-color: #902dec;
    }

    // .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    //     border-color: #902dec;
    // }

}

.petcare {
    // @include mat.all-component-colors($theme-green);
    @include mat.button-theme($theme-green);
    @include mat.fab-theme($theme-green);
    @include mat.checkbox-theme($theme-green);

    --mdc-checkbox-selected-checkmark-color: #fff;

    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
        color: #D0021B;
    }

    .mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker{
        color: #219653;
    }

    .mat-mdc-button_border{
        border-color: #219653;
    }

    // .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    //     border-color: #219653;
    // }

}

.driver {
    // @include mat.all-component-colors($theme-cryola);
    @include mat.button-theme($theme-cryola);
    @include mat.fab-theme($theme-cryola);
    @include mat.checkbox-theme($theme-cryola);

    --mdc-checkbox-selected-checkmark-color: #fff;

    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
        color: #D0021B;
    }

    .mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker{
        color: #7986CB;
    }

    .mat-mdc-button_border{
        border-color: #7986CB;
    }

    // .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    //     border-color: #7986CB;
    // }
}

.cook {
    // @include mat.all-component-colors($theme-bondi);
    @include mat.button-theme($theme-bondi);
    @include mat.fab-theme($theme-bondi);
    @include mat.checkbox-theme($theme-bondi);

    --mdc-checkbox-selected-checkmark-color: #fff;

    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
        color: #D0021B;
    }

    .mat-form-field.mat-focused .mat-form-field-label .mat-form-field-required-marker{
        color: #0097a7;
    }

    .mat-mdc-button_border{
        border-color: #0097a7;
    }

    // .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    //     border-color: #0097a7;
    // }
}


.mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: #333
}
